import * as React from 'react'

import * as styles from './Checkbox.module.scss'
import { Props } from './types'

const Checkbox = ({
  name,
  children,
  checked,
  onChange,
  className,
  tabIndex,
}: Props): JSX.Element => (
  <React.Fragment>
    <input
      onChange={onChange}
      type="checkbox"
      checked={checked}
      name={name}
      id={name}
      tabIndex={tabIndex}
      className={[styles.checkbox, className].join(' ')}
    />
    <label htmlFor={name}>
      <span>{children}</span>
    </label>
  </React.Fragment>
)

export default Checkbox

import React, { useEffect, useRef, useState } from 'react'
import SbEditable from 'storyblok-react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'
import { navigate } from 'gatsby-link'
import { useRecoilState } from 'recoil'
import debounce from 'debounce'
import isNode from 'is-node'

import Favorites from 'src/components/Favorites/Favorites'
import FavoriteCount from 'src/components/Favorites/FavoriteCount/FavoriteCount'
import Markdown from 'src/components/Markdown/Markdown'
import { activeMenuState } from 'src/recoil/atoms'
import { Logotype } from 'src/components/UI/Logotype/Logotype'
import { Tagline } from 'src/components/UI/Tagline/Tagline'
import Input from 'src/components/UI/Input/Input'
import { SearchBigIcon, MenuIcon, CloseIcon } from 'src/components/UI/Icon/Icon'
import { SbLink } from 'src/storyblok/types/storyblokTypes'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import { Container } from 'src/components/UI/Grid/Grid'

import NavigationDesktop from './NavigationDesktop/NavigationDesktop'
import NavigationMobile from './NavigationMobile/NavigationMobile'
import Search from './Search/Search'
import { Props } from './types'
import * as styles from './Header.module.scss'
import NavigationDesktopContent from './NavigationDesktopContent/NavigationDesktopContent'

const Header = ({ blok, fullSlug }: Props): JSX.Element => {
  const {
    usp_bar_links,
    usp_bar_messages,
    navigation_groups,
    popular_searches,
    popular_countries,
    popular_producers,
    popular_articles,
    popular_products,
  } = blok
  const uspRef = useRef(null)
  let preventNavSet = false
  const [activeNavItem, setActiveNavItem] = useState(-1)
  const [searchWord, setSearchWord] = useState('')
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)

  const setNavItem = debounce((index: number) => {
    if (preventNavSet) {
      return false
    }
    setActiveMenu('nav')
    setActiveNavItem(index)
  }, 100)

  const closeNavItem = debounce(() => {
    if (activeMenu === 'nav') {
      setActiveMenu('')
      setTimeout(() => {
        setActiveNavItem(-1)
      }, 300)
    }
  }, 20)

  const closeMenus = () => {
    setActiveMenu('')
    setActiveNavItem(-1)
  }

  const onSearch = (e: any) => {
    const word = e.target.value

    setSearchWord(word)

    if (word) {
      setActiveNavItem(-1)
      setActiveMenu('search')
    } else {
      setActiveMenu('')
    }
  }

  const resetSearch = () => {
    const mobileEl = document.getElementById('mobile-search')
    const deskEl = document.getElementById('desktop-search')
    if (mobileEl && mobileEl.value) {
      mobileEl.value = ''
    }
    if (deskEl && deskEl.value) {
      deskEl.value = ''
    }

    setSearchWord('')
    setActiveMenu('')
  }

  const goToSearchPage = () => {
    resetSearch()
  }

  useEffect(() => {
    if (usp_bar_messages?.length > 1 && uspRef && uspRef.current) {
      let index = 0
      const children = uspRef.current.children
      setInterval(() => {
        if (children && children[index]) {
          children[index].style.opacity = 0
          children[index].style.zIndex = 0
          const cur = index
          setTimeout(() => {
            children[cur].children[0].style.transform = 'translateY(120%)'
          }, 700)
          index++
          if (index >= children.length) {
            index = 0
          }
          if (children && children[index]) {
            setTimeout(() => {
              children[index].style.zIndex = 1
              children[index].style.opacity = 1
              children[index].children[0].style.transform = 'translateY(0%)'
            }, 1000)
          }
        }
      }, 8000)
    }
  }, [])

  return (
    <SbEditable content={blok}>
      <Box
        className={classnames(
          styles.navigationOverlay,
          activeMenu === 'nav' ? styles.active : ''
        )}
      >
        <NavigationMobile
          navigationGroups={navigation_groups}
          uspBarLinks={usp_bar_links}
        />
      </Box>
      <Favorites />

      <Box
        className={classnames(
          styles.overlay,
          activeMenu === 'nav' ||
            activeMenu === 'search' ||
            activeMenu === 'favorites' ||
            activeMenu === 'guide'
            ? styles.active
            : '',
          activeMenu === 'favorites' || activeMenu === 'guide'
            ? styles.fullWindow
            : ''
        )}
        onClick={closeMenus}
      ></Box>

      <Box className={styles.sticky}>
        <Container className={styles.uspBar}>
          <Flex alignItems="center" height="100%">
            <Flex className="hideMobile" flex="1" width={1}>
              {usp_bar_links?.length > 0 &&
                usp_bar_links.map((link: SbLink) => {
                  const { url, title } = link ? getLinkAttributes(link) : {}
                  return (
                    <Box key={link._uid} marginRight={[4]} marginTop={[1]}>
                      <LinkComponent to={url}>{title}</LinkComponent>
                    </Box>
                  )
                })}
            </Flex>
            <Box
              ref={uspRef}
              className={styles.rotatingMessages}
              flex="2"
              width={1}
            >
              {usp_bar_messages?.length > 0 &&
                usp_bar_messages.map((content: SbLink, index: number) => {
                  return (
                    <Box
                      ref={uspRef}
                      as="button"
                      className={styles.buttonPopup}
                      onClick={() => setActiveMenu('guide')}
                      key={`message-${index}`}
                    >
                      <Flex
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        className={styles.messageWrapper}
                        key={content._uid}
                      >
                        <Box marginTop={[1]}>
                          {content?.markdown && (
                            <Markdown source={content.markdown} />
                          )}
                        </Box>
                      </Flex>
                    </Box>
                  )
                })}
            </Box>
            <Box flex="1" className="hideMobile" width={1} />
          </Flex>
        </Container>

        <Box
          width={1}
          as="header"
          className={classnames(
            styles.header,
            activeMenu === 'nav' ? styles.active : ''
          )}
        >
          <Box
            style={{ position: 'relative', zIndex: '-1' }}
            onMouseLeave={() => {
              if (!isNode && typeof window !== 'undefined') {
                if (window && window.innerWidth > 1023) {
                  closeNavItem()
                  preventNavSet = true
                  setTimeout(() => {
                    preventNavSet = false
                  }, 300)
                }
              }
            }}
            width={1}
          >
            <Container className={styles.mainHeader}>
              <Flex
                width={1}
                className={styles.navigationWrapper}
                flexWrap={['wrap', null, null, 'no-wrap']}
                justifyContent="space-between"
                alignItems="center"
                height="100%"
              >
                <Flex
                  className="hideDesktop hidePrint"
                  alignItems="center"
                  height="100%"
                  flex="1"
                  width="100%"
                >
                  <Box
                    as="button"
                    className={styles.menuButton}
                    aria-label="Toggle menu"
                    onClick={() => setActiveMenu(activeMenu ? '' : 'nav')}
                  >
                    <MenuIcon />
                  </Box>
                </Flex>
                <Flex
                  className="hideMobile hidePrint"
                  alignItems="center"
                  height="100%"
                  flex="1"
                  width="100%"
                >
                  <NavigationDesktop
                    setNavItem={setNavItem}
                    closeNavItem={closeNavItem}
                    activeItem={activeNavItem}
                    navigationGroups={navigation_groups}
                    fullSlug={fullSlug}
                  />
                </Flex>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  flex={['2', null, null, '1']}
                  width="100%"
                >
                  <LinkComponent to="/" className={styles.logotype}>
                    <Logotype />
                  </LinkComponent>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    className={styles.tagline}
                    width={1}
                    marginTop={3}
                  >
                    <Tagline />
                  </Flex>
                </Flex>
                <Flex
                  justifyContent="flex-end"
                  alignItems="center"
                  flex="1"
                  width="100%"
                  className="hidePrint"
                >
                  <Flex
                    className="hideMobile"
                    justifyContent="flex-end"
                    alignItems="center"
                    width={1}
                  >
                    <Box marginRight={['10px']}>
                      <SearchBigIcon />
                    </Box>
                    <Flex
                      alignItems="center"
                      className={styles.searchBarWrapper}
                    >
                      <Input
                        className={styles.searchBar}
                        type="text"
                        name="search"
                        id="desktop-search"
                        onChange={debounce((e: any) => {
                          onSearch(e)
                        }, 150)}
                        onKeyUp={(e: any) => {
                          e.preventDefault()
                          if (e.key === 'Enter' || e.keyCode === 13) {
                            navigate(`/search#search=${searchWord}`)
                            resetSearch()
                          }
                        }}
                        placeholder="Sök bland våra viner..."
                        onFocus={() => {
                          setActiveNavItem(-1)
                          setActiveMenu('search')
                        }}
                      />
                      {searchWord && (
                        <Flex
                          alignItems="center"
                          as="button"
                          className={styles.resetSearchButton}
                          onClick={() => resetSearch()}
                        >
                          <CloseIcon width={14} height={14} />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                  <Flex
                    className={styles.favoriteCount}
                    alignItems="center"
                    marginLeft={[3]}
                    marginBottom={[0, null, null, 1]}
                  >
                    <FavoriteCount />
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                className={classnames(styles.searchMobile, 'hideDesktop')}
                marginX={['-16px']}
                paddingX={[4]}
              >
                <Flex alignItems="center" width={1}>
                  <Flex alignItems="center" width={1}>
                    <Box marginRight={['10px']} height="28px">
                      <SearchBigIcon />
                    </Box>
                    <Flex
                      alignItems="center"
                      className={styles.searchBarWrapper}
                      width={1}
                    >
                      <Input
                        className={styles.searchBar}
                        type="text"
                        name="search"
                        id="mobile-search"
                        placeholder="Sök bland våra viner..."
                        onChange={debounce((e: any) => {
                          onSearch(e)
                        }, 150)}
                        onFocus={() => {
                          setActiveNavItem(-1)
                          setActiveMenu('search')
                        }}
                      />
                      {activeMenu === 'search' && (
                        <Flex
                          alignItems="center"
                          as="button"
                          className={styles.resetSearchButton}
                          onClick={() => resetSearch()}
                        >
                          <CloseIcon width={14} height={14} />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Container>

            <Flex
              className={
                activeMenu === 'search'
                  ? [styles.search, styles.active].join(' ')
                  : styles.search
              }
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Box className={styles.sidebar}>
                <Search
                  searchWord={searchWord}
                  popularSearches={popular_searches}
                  popularCountries={popular_countries}
                  popularArticles={popular_articles}
                  popularProducers={popular_producers}
                  popularProducts={popular_products}
                  resetSearch={() => resetSearch()}
                />
              </Box>
            </Flex>

            <NavigationDesktopContent
              closeNavItem={closeNavItem}
              activeItem={activeNavItem}
              navigationGroups={navigation_groups}
              fullSlug={fullSlug}
            />
          </Box>
        </Box>
      </Box>
    </SbEditable>
  )
}

export default Header

import { isArray } from 'lodash'

// Removes item  if it already exists as favorite.
// Can be use for remove item also
export const getUpdatedFavoritesArray = (item: any, currentState: any[]) => {
  let found = false
  if (isArray(currentState)) {
    currentState = currentState.filter((inner: any) => {
      if (inner.sku === item.sku) {
        found = true
        return false
      } else {
        return true
      }
    })
    if (!found) {
      currentState.push(item)
    }
    return currentState
  } else {
    return []
  }
}

export const isFavorite = (item: any, currentState: any[]) => {
  if (isArray(currentState)) {
    const favorite = currentState.find((inner: any) => {
      if (inner.sku === item.sku) {
        return true
      } else {
        return false
      }
    })
    if (favorite) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'

import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './NavigationDesktop.module.scss'
import { Props } from './types'

const NavigationDesktop = ({
  navigationGroups,
  setNavItem,
  closeNavItem,
  activeItem,
  fullSlug,
}: Props): JSX.Element => {
  return (
    <Flex height="100%" className={styles.wrapper}>
      {navigationGroups?.length > 0 &&
        navigationGroups.map((group: any, index: number) => {
          const { link, columns } = group

          const { url, title } =
            link && link?.length > 0 ? getLinkAttributes(link[0]) : {}

          const isActive =
            fullSlug &&
            fullSlug.split('/').length > 0 &&
            url &&
            url.split('/').length > 0 &&
            fullSlug.split('/')[0] === url.split('/')[0]
              ? true
              : false

          return (
            <React.Fragment key={group._uid}>
              <Flex
                alignItems="center"
                paddingX={[4]}
                marginLeft={['-16px']}
                onMouseEnter={
                  columns?.length > 0 ? () => setNavItem(index) : null
                }
                className={classnames(
                  styles.groupTitle,
                  index === activeItem ? styles.active : '',
                  isActive ? styles.isTopPage : ''
                )}
              >
                <Flex
                  className={styles.groupTitleBorder}
                  alignItems="center"
                  height="100%"
                >
                  <Box as="span" marginTop={[1]}>
                    <LinkComponent
                      onClick={() => {
                        closeNavItem()
                      }}
                      to={url}
                    >
                      {title}
                    </LinkComponent>
                  </Box>
                </Flex>
              </Flex>
            </React.Fragment>
          )
        })}
    </Flex>
  )
}

export default NavigationDesktop

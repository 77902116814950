import React, { useEffect, useState } from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useRecoilState } from 'recoil'

import { favoritesState, activeMenuState } from 'src/recoil/atoms'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import { Heart } from 'src/components/UI/Icon/Icon'

import * as styles from './FavoriteCount.module.scss'

const FavoriteCount = (): JSX.Element => {
  const [activeFavorites] = useRecoilState(favoritesState)
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(true)
    setTimeout(() => {
      setAnimate(false)
    }, 500)
  }, [activeFavorites])

  const classNames = [styles.badge]
  if (animate) {
    classNames.push(styles.animate)
  }
  if (activeFavorites?.length > 0) {
    classNames.push(styles.active)
  }

  return (
    <Flex
      onClick={() =>
        setActiveMenu(activeMenu !== 'favorites' ? 'favorites' : '')
      }
      alignItems="center"
      as="button"
      aria-label="Toggle favorites menu"
      className={styles.wrapper}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        className={classNames.join(' ')}
      >
        <Box as="span" className={styles.number}>
          {activeFavorites?.length}
        </Box>
      </Flex>
      <Heart width={22} height={22} />
    </Flex>
  )
}

export default FavoriteCount

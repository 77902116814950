let json = []

try {
  json = require('../json/dataSources.json')
} catch (error) {
  console.log('JSON NOT FOUND')
}

const getDataSourceEntryValue = (name, dataSource) => {
  const response = dataSource
    ? json.find((item) => item.name === name && item.data_source === dataSource)
    : json.find((item) => item.name === name)

  if (response && response.value) {
    return response.value
  } else {
    return ''
  }
}

const getDataSourceEntryName = (value, dataSources) => {
  const source = dataSources ? dataSources : json
  const response = source.find((item) => item.value === value)

  if (response && response.name) {
    return response.name
  } else {
    return ''
  }
}

const getDataSourceEntrySlug = (value) => {
  const response = json.find((item) => item.value === value)

  if (response && response.slug) {
    return response.slug
  } else {
    return null
  }
}

const getDataSourceEntryByValue = (value) => {
  const response = json.find((item) => item.value === value)

  if (response) {
    return response
  } else {
    return { name: '', value: value, data_source: '' }
  }
}

const getDataSourceEntriesByValues = (values, dataSource, dataSources) => {
  if (!values || !dataSource) return []
  const response = values.map((value) => {
    const source = dataSources ? dataSources : json
    const result = source
      .filter((item) => item.data_source === dataSource)
      .find((item) => item.value === value)

    if (result) {
      return result
    } else {
      return { name: '', value: value, data_source: '' }
    }
  })

  if (response && response.length > 0) {
    return [...response]
  } else {
    return []
  }
}

const getDataSourceEntries = (dataSource) => {
  const response = json.filter((item) => item.data_source === dataSource)

  if (response && response.length > 0) {
    return [...response]
  } else {
    return []
  }
}

const getDataSourceEntriesNames = (values) => {
  const response = values.map((value) => {
    const result = json.find((item) => item.value === value)
    if (result && result.name) {
      return result.name
    } else {
      return ''
    }
  })

  if (response && response.length > 0) {
    return [...response]
  } else {
    return []
  }
}

const getDataSourceEntriesValues = (names) => {
  const response = names.map((name) => {
    const result = json.find((item) => item.name === name)
    if (result && result.value) {
      return result.value
    } else {
      return ''
    }
  })

  if (response && response.length > 0) {
    return [...response]
  } else {
    return []
  }
}

module.exports = {
  getDataSourceEntryValue,
  getDataSourceEntryName,
  getDataSourceEntryByValue,
  getDataSourceEntriesByValues,
  getDataSourceEntries,
  getDataSourceEntriesNames,
  getDataSourceEntriesValues,
  getDataSourceEntrySlug,
}

import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'
import { useLocation } from '@reach/router'

import loadable from '@loadable/component'
import { parseStoryblokProductToAlgolia } from 'src/algolia/clienthelpers'
import ButtonSecondary from 'src/components/UI/Button/ButtonSecondary/ButtonSecondary'
import { Product } from 'src/globalTypes/types'
// import ProductsContainer from 'src/components/ProductsContainer/ProductsContainer'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { Column } from 'src/components/UI/Grid/Grid'
import {
  gtagUAPageView,
  gtagGA4Search,
} from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'

import ProductResult from './ProductResult/ProductResult'
import * as styles from './Search.module.scss'
import { Props } from './types'

const ProductsContainer = loadable(
  () => import('../../../../components/ProductsContainer/ProductsContainer')
)

const LeftColumn = ({
  title: columnTitle,
  links,
  resetSearch,
  type,
  className,
  searchWord,
}: any) => {
  const locationHref = useLocation ? useLocation().href : ''

  return (
    <Flex
      className={[styles.leftColumn, className].join(' ')}
      flexDirection="column"
      marginTop={[4, null, null, 0]}
      paddingTop={[0, null, null, 4]}
      paddingBottom={[0, null, null, 2]}
    >
      <Box className={styles.title} paddingBottom={[1, null, null, 1]}>
        {columnTitle}
      </Box>
      {links && links.length > 0 ? (
        links.map(({ text, title, slug }: any, index: number) => {
          const linkTitle = type === 'post' && title ? title : text ? text : ''

          return (
            <LinkComponent
              key={`${text}-${index}`}
              className={styles.linkResult}
              to={type === 'post' ? slug : `/search#search=${text}`}
              title={text}
              onClick={() => {
                resetSearch(),
                  gtagUAPageView({
                    title: linkTitle,
                    location: locationHref,
                    path: `${slug}?search=${searchWord}`,
                    dimension1: `${columnTitle} - ${linkTitle}`,
                  }),
                  gtagGA4Search({
                    search_term: searchWord,
                    search_click: `${columnTitle} - ${linkTitle}`,
                  })
              }}
            >
              {linkTitle}
            </LinkComponent>
          )
        })
      ) : (
        <Box className={styles.linkResult}>{'Inga sökförslag hittades'}</Box>
      )}
    </Flex>
  )
}

const Popular = ({ popularProducts, resetSearch }: any) => (
  <Box className={styles.results}>
    {popularProducts?.length > 0 && (
      <Box>
        <Box
          className={classnames(styles.title, styles.withBorder)}
          paddingTop={[4, null, null, 0]}
          paddingBottom={[3, null, null, 3]}
        >
          Mest sökta viner
        </Box>
        {popularProducts.map((product: Product) => {
          const parsedProduct = parseStoryblokProductToAlgolia(product)
          return (
            <ProductResult
              key={parsedProduct.id}
              product={parsedProduct}
              onClick={resetSearch}
            />
          )
        })}
      </Box>
    )}
  </Box>
)

const Search = ({
  searchWord,
  resetSearch,
  popularSearches,
  popularProducts,
  popularArticles,
  popularCountries,
  popularProducers,
}: Props): JSX.Element => {
  const locationHref = useLocation ? useLocation().href : ''
  return (
    <Box height="100%" className={styles.wrapper}>
      {searchWord ? (
        <ProductsContainer
          productIds={[]}
          products={[]}
          isSearchBar={true}
          searchWord={searchWord}
          perPage={8}
          render={(data) => {
            const {
              loading,
              products,
              totalHits,
              articles,
              producers,
              countries,
              regions,
            } = data
            return (
              <Flex flexDirection={['column', null, null, 'row']}>
                <Column
                  paddingX={[4, null, null, 6]}
                  marginTop={[0, null, null, '-16px']}
                  paddingBottom={['200px', null, null, 0]}
                  className={styles.leftSection}
                  order={[1, null, null, 0]}
                  width={[1, null, null, 2 / 6]}
                >
                  {/* <LeftColumn
                    title="Populära Sökningar"
                    links={popularSearches}
                    resetSearch={resetSearch}
                  /> */}
                  <LeftColumn
                    title="Vinspiration"
                    links={articles}
                    type="post"
                    resetSearch={resetSearch}
                    searchWord={searchWord}
                  />
                  <LeftColumn
                    title="Producenter"
                    links={producers}
                    type="post"
                    resetSearch={resetSearch}
                    searchWord={searchWord}
                  />
                  <LeftColumn
                    title="Länder & regioner"
                    links={[...countries, ...regions]}
                    type="post"
                    resetSearch={resetSearch}
                    searchWord={searchWord}
                  />
                </Column>

                <Column
                  order={[0, null, null, 1]}
                  paddingX={[4, null, null, 6]}
                  width={[1, null, null, 4 / 6]}
                  className={styles.content}
                  height="100%"
                >
                  {totalHits < 1 && !loading && searchWord && (
                    <Box
                      marginY={[4, null, null, 10]}
                      className={styles.message}
                    >
                      Vi kan tyvärr inte hitta det du söker. Testa att söka på
                      något annat eller se populära sökningar nedan.
                    </Box>
                  )}

                  {totalHits && totalHits > 0 && products?.length > 0 ? (
                    <React.Fragment>
                      <Box
                        className={
                          loading
                            ? [styles.results, styles.loading].join(' ')
                            : styles.results
                        }
                      >
                        <Box
                          className={classnames(
                            styles.title,
                            styles.withBorder
                          )}
                          paddingTop={[4, null, null, 0]}
                          paddingBottom={[3, null, null, 3]}
                        >
                          Viner
                        </Box>
                        {products.map((product: Product) => {
                          const { name, price, slug, media, sku, status, url } =
                            product
                          return (
                            <ProductResult
                              key={product.id}
                              product={product}
                              onClick={() => {
                                resetSearch(),
                                  gtagUAPageView({
                                    title: name,
                                    location: locationHref,
                                    path: `${slug}?search=${searchWord}`,
                                    dimension1: `Viner - ${name}`,
                                  }),
                                  gtagGA4Search({
                                    search_term: searchWord,
                                    search_click: `Viner - ${name}`,
                                  })
                              }}
                            />
                          )
                        })}
                      </Box>

                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        className={styles.bottom}
                      >
                        <ButtonSecondary
                          to={`/search#search=${searchWord}`}
                          onClick={resetSearch}
                        >
                          {`Se alla resultat (${totalHits})`}
                        </ButtonSecondary>
                      </Flex>
                    </React.Fragment>
                  ) : (
                    <Popular
                      popularSearches={popularSearches}
                      popularProducts={popularProducts}
                      resetSearch={resetSearch}
                    />
                  )}
                </Column>
              </Flex>
            )
          }}
        />
      ) : (
        <Flex flexDirection={['column', null, null, 'row']}>
          <Column
            paddingX={[4, null, null, 6]}
            marginTop={[0, null, null, '-16px']}
            paddingBottom={['200px', null, null, 0]}
            className={styles.leftSection}
            order={[1, null, null, 0]}
            width={[1, null, null, 2 / 6]}
          >
            <LeftColumn
              className="hideMobile"
              title="Mest sökt"
              links={popularSearches}
              resetSearch={resetSearch}
            />
            <LeftColumn
              title="Vinspiration"
              links={popularArticles?.map((post: any) => ({
                title: post.name,
                slug: post.full_slug,
              }))}
              type="post"
              resetSearch={resetSearch}
            />
            <LeftColumn
              title="Producenter"
              type="post"
              links={popularProducers?.map((post: any) => ({
                title: post.name,
                slug: post.full_slug,
              }))}
              resetSearch={resetSearch}
            />
            <LeftColumn
              type="post"
              title="Länder & regioner"
              links={popularCountries?.map((post: any) => ({
                title: post.name,
                slug: post.full_slug,
              }))}
              resetSearch={resetSearch}
            />
          </Column>
          <Column
            order={[0, null, null, 1]}
            paddingX={[4, null, null, 6]}
            width={[1, null, null, 4 / 6]}
            className={styles.content}
            height="100%"
          >
            <LeftColumn
              className="hideDesktop"
              title="Mest sökt"
              links={popularSearches}
              resetSearch={resetSearch}
            />
            <Popular
              popularSearches={popularSearches}
              popularProducts={popularProducts}
              resetSearch={resetSearch}
            />
          </Column>
        </Flex>
      )}
    </Box>
  )
}

export default Search

import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'
import SbEditable from 'storyblok-react'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import ButtonBorder from 'src/components/UI/Button/ButtonBorder/ButtonBorder'
import Image from 'src/components/UI/Image/Image'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import Markdown from 'src/components/Markdown/Markdown'
import { ArrowRight } from 'src/components/UI/Icon/Icon'

import * as styles from './Highlight.module.scss'
import { Props } from './types'
import StoryblokVideo, { getMediaType } from '../StoryblokVideo/StoryblokVideo'

const HighlightContent = ({
  blok,
  type,
  size,
  cta,
  preload,
  htmlHeading,
}: Props): JSX.Element => {
  const { label, title, media, theme } = blok
  const mediaType = media ? getMediaType(media.filename) : ''
  const wrapperStyles = classnames(
    styles.wrapper,
    theme === 'light' ? styles.lightTheme : '',
    size === 'large'
      ? styles.largeTheme
      : size === 'small'
      ? styles.smallTheme
      : '',
    type === 'fixed' ? styles.fixedTheme : ''
  )

  return (
    <Flex
      className={wrapperStyles}
      flexDirection="column"
      width={['100%', null, null, '100%']}
      height={['100%', null, null, '100%']}
    >
      {mediaType === 'video' ? (
        <Box className={styles.video}>
          <StoryblokVideo autoplay fill src={media.filename} />
        </Box>
      ) : (
        media?.filename && (
          <Box className={styles.image}>
            <Image
              preload={preload}
              sizes={
                size === 'large'
                  ? {
                      mobile: '100vw',
                      tablet: '50vw',
                      desktop: '50vw',
                    }
                  : {
                      mobile: '50vw',
                      tablet: '30vw',
                      desktop: '30vw',
                    }
              }
              src={media.filename}
              alt={media?.alt ? media.alt : title ? title : label ? label : ''}
            />
          </Box>
        )
      )}

      <Flex className={styles.content} flexDirection="column">
        {label && (
          <Box className={styles.label} marginBottom={[1, null, null, 1]}>
            {label}
          </Box>
        )}
        {title && (
          <Box className={styles.title} as={htmlHeading}>
            <Markdown source={title} />
          </Box>
        )}
        {size === 'large' && cta && cta?.url && cta?.title && (
          <ButtonBorder to={cta.url} title={cta.title} theme={theme}>
            {cta.title}
          </ButtonBorder>
        )}
        {size !== 'large' && cta && cta?.url && (
          <ArrowRight className={styles.arrowIcon} width={24} height={20} />
        )}
      </Flex>
    </Flex>
  )
}

const Highlight = ({
  blok,
  type,
  size,
  onClick,
  preload,
  htmlHeading = 'h2',
}: Props): JSX.Element => {
  const { link } = blok

  const { url, title } =
    link && link?.length > 0 ? getLinkAttributes(link[0]) : {}

  return (
    <SbEditable content={blok}>
      {size !== 'large' && url ? (
        <LinkComponent
          onClick={() => {
            if (onClick) {
              onClick()
            }
          }}
          className={styles.link}
          to={url}
          title={title}
        >
          <HighlightContent
            blok={blok}
            preload={preload}
            type={type}
            size={size}
            cta={{ url, title }}
            htmlHeading={htmlHeading}
          />
        </LinkComponent>
      ) : (
        <HighlightContent
          blok={blok}
          type={type}
          preload={preload}
          size={size}
          cta={{ url, title }}
          htmlHeading={htmlHeading}
        />
      )}
    </SbEditable>
  )
}

export default Highlight

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-editor-index-tsx": () => import("./../../../src/pages/editor/index.tsx" /* webpackChunkName: "component---src-pages-editor-index-tsx" */),
  "component---src-templates-404-404-tsx": () => import("./../../../src/templates/404/404.tsx" /* webpackChunkName: "component---src-templates-404-404-tsx" */),
  "component---src-templates-search-search-tsx": () => import("./../../../src/templates/search/search.tsx" /* webpackChunkName: "component---src-templates-search-search-tsx" */),
  "component---src-templates-storyblok-entry-js": () => import("./../../../src/templates/storyblok-entry.js" /* webpackChunkName: "component---src-templates-storyblok-entry-js" */)
}


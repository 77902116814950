import { atom } from 'recoil'
import isNode from 'is-node'
// import { useLocation } from '@reach/router'

import { gtagEvent } from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'

export const activeMenuState = atom({
  key: 'activeMenu',
  default: '' as string,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newActiveMenu) => {
        if (document) {
          if (newActiveMenu) {
            document.body.classList.add('prevent-overflow')
            if (newActiveMenu === 'nav') {
              // document.body.classList.add('enable-overflow-desktop')
            } else {
              // document.body.classList.remove('enable-overflow-desktop')
            }
          } else {
            document.body.classList.remove('prevent-overflow')
            document.body.classList.remove('enable-overflow-desktop')
          }
        }
      })
    },
  ],
})

export const activePopupState = atom({
  key: 'activePopup',
  default: { url: '', name: '', price: 0, slug: '' } as {
    url: string
    name: string
    price: number
  } | null,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newActiveMenu) => {
        if (document) {
          if (newActiveMenu) {
            document.body.classList.add('prevent-overflow')
          } else {
            document.body.classList.remove('prevent-overflow')
          }
        }
      })
    },
  ],
})

// export const windowSizeState = atom({
//   key: 'windowSize',
//   default: {
//     width: 0,
//     height: 0,
//   } as {
//     width: number
//     height: number
//   },
// })

export const topListState = atom({
  key: 'topList',
  default: [''] as [string],
})

// Set from Storyblok
export const ourFavoritesState = atom({
  key: 'ourFavorites',
  default: [''] as [string],
})

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet, ...rest }: any) => {
    if (!isNode) {
      const savedValue = localStorage.getItem(key)
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue))
      }

      onSet((newValue: any) => {
        localStorage.setItem(key, JSON.stringify(newValue))
      })
    }
  }

export const favoritesState = atom({
  key: 'favorites',
  default: [] as any[],
  effects_UNSTABLE: [localStorageEffect('user_favorites')],
})

export const userActivityState = atom({
  key: 'userActivity',
  default: { hasScrolled: false, hasStayed: false } as {
    hasScrolled: boolean
    hasStayed: boolean
  },
  effects_UNSTABLE: [
    ({ setSelf, onSet }: any) => {
      if (!isNode) {
        onSet((newValue: any) => {
          console.log('update state')
          if (newValue.hasScrolled && newValue.hasStayed) {
            const pagePath =
              typeof window !== 'undefined' && window?.location?.href
                ? window.location.href
                : ''

            console.log('register page quality event')
            gtagEvent({
              action: '2 min + 25% scroll',
              category: 'Page Quality Event',
              label: pagePath,
            })
          }
        })
      }
    },
  ],
})

import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useRecoilState } from 'recoil'
import { useLocation } from '@reach/router'

import { activePopupState } from 'src/recoil/atoms'
import { CloseIcon, BagWithCheckMark } from 'src/components/UI/Icon/Icon'
import Markdown from 'src/components/Markdown/Markdown'
import ButtonPrimary from 'src/components/UI/Button/ButtonPrimary/ButtonPrimary'
import { gtagEvent } from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'

import * as styles from './ProductPopup.module.scss'
import { Props } from './types'

const ProductPopup = ({ blok }: Props): JSX.Element => {
  const { title, text, button_text } = blok
  const [activePopup, setActivePopup] = useRecoilState(activePopupState)
  const { url, name, price } = activePopup || {}
  const locationHref = useLocation ? useLocation().href : ''

  return url ? (
    <SbEditable content={blok}>
      <Flex
        className={styles.overlay}
        justifyContent="center"
        alignItems="center"
        paddingX={[6, null, null]}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          className={styles.modal}
          width={[1, null, '512px']}
          paddingTop={[5]}
          paddingBottom={[10]}
        >
          <Flex
            justifyContent="flex-end"
            width={1}
            paddingX={[5]}
            marginBottom={[3]}
          >
            <Box
              as="button"
              onClick={() => {
                setActivePopup(null)
              }}
              className={styles.close}
            >
              <CloseIcon />
            </Box>
          </Flex>
          <Flex
            textAlign="center"
            paddingX={[6, null, null, 23]}
            flexDirection="column"
            alignItems="center"
          >
            <Flex
              className={styles.badge}
              alignItems="center"
              justifyContent="center"
              marginBottom={[4, null, null, 6]}
            >
              <BagWithCheckMark />
            </Flex>
            {title && <Box className={styles.title}>{title}</Box>}
            {text && (
              <Box marginTop={[4]}>
                <Markdown source={text} />
              </Box>
            )}
            {url && (
              <Box marginTop={[4]}>
                <ButtonPrimary
                  to={url}
                  onClick={
                    activePopup
                      ? () => {
                          setActivePopup({
                            url: '',
                            name: '',
                            price: 0,
                          })
                          gtagEvent({
                            action: name,
                            category: 'Click - Buy',
                            label: locationHref,
                            value: price,
                          })
                        }
                      : null
                  }
                >
                  {button_text}
                </ButtonPrimary>
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </SbEditable>
  ) : (
    <div />
  )
}

export default ProductPopup

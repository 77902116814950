import React from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { ArrowRight } from 'src/components/UI/Icon/Icon'

import * as styles from './ButtonBorder.module.scss'
import { Props } from './types'

const ButtonContent = ({
  children,
  customIcon,
  useIcon,
}: Props): JSX.Element => (
  <Flex alignItems="center">
    <Box className={styles.text} marginRight={2} textAlign="left">
      {children}
    </Box>
    {useIcon && (
      <React.Fragment>
        {customIcon ? (
          customIcon
        ) : (
          <ArrowRight width={11} height={9} strokeWidth={3} color="#000000" />
        )}
      </React.Fragment>
    )}
  </Flex>
)

const ButtonBorder = ({
  onClick,
  to,
  title,
  children,
  className = '',
  customIcon,
  theme = '',
  useIcon = true,
  disabled = false,
}: Props) => {
  const buttonStyles = classnames(
    styles.button,
    theme === 'light' ? styles.lightTheme : '',

    className
  )
  return onClick && !to ? (
    <Flex
      as="button"
      onClick={onClick}
      disabled={disabled}
      className={buttonStyles}
    >
      <ButtonContent customIcon={customIcon} useIcon={useIcon}>
        {children}
      </ButtonContent>
    </Flex>
  ) : to ? (
    <LinkComponent
      to={to}
      onClick={onClick}
      title={title}
      className={buttonStyles}
    >
      <ButtonContent customIcon={customIcon} useIcon={useIcon}>
        {children}
      </ButtonContent>
    </LinkComponent>
  ) : null
}

export default ButtonBorder

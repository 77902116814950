// extracted by mini-css-extract-plugin
export var active = "Footer-module--active--3JNeu";
export var button = "Footer-module--button--MvcKZ";
export var column = "Footer-module--column--+9h0i";
export var content = "Footer-module--content--aeP3R";
export var cookieSettings = "Footer-module--cookieSettings--+Ur+8";
export var icon = "Footer-module--icon--Itm2R";
export var logotype = "Footer-module--logotype--DEJ4j";
export var printBlock = "Footer-module--printBlock--atyHI";
export var socialLink = "Footer-module--socialLink--GKs4s";
export var title = "Footer-module--title--cP7cc";
export var wrapper = "Footer-module--wrapper--jJ9Zt";
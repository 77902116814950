import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'
import { useRecoilState } from 'recoil'

import Input from 'src/components/UI/Input/Input'
import ButtonBorder from 'src/components/UI/Button/ButtonBorder/ButtonBorder'
import { ArrowRight } from 'src/components/UI/Icon/Icon'
import { favoritesState } from 'src/recoil/atoms'

import * as styles from '../../Favorites.module.scss'
import { Props } from './types'

const Step2 = ({ step, setStep }: Props): JSX.Element => {
  const [activeFavorites] = useRecoilState(favoritesState)

  const [form, setForm] = useState({
    message: '',
    email: '',
    validEmail: false,
  })

  const validateForm = (event: any, key: string, validateEmail?: boolean) => {
    const { value } = event.target

    if (validateEmail) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const isValid = re.test(String(value).toLowerCase())
      setForm({ ...form, [key]: value, validEmail: isValid })
    } else {
      setForm({ ...form, [key]: value })
    }
  }

  const sendMail = (mailToLink: string) => {
    if (window) {
      window.open(mailToLink)
    }
  }

  const getMailtoLink = (mail: string, title: string, products: any[]) => {
    const subject = `Favoritlista från Ward Wines`
    const header = `Hej!

Vad kul att du skapat en favoritlista hos Ward Wines.
Nedan följer ditt urval:

${title}

`

    const productList = products
      .map(
        (product) =>
          `${product.producerName}
${product.name}
${product.price} SEK
${product.sku}
`
      )
      .join('\n')

    const footer = `
Välkommen tillbaka till Ward Wines värld av viner!!

www.wardwines.se`

    const body = header.concat(productList, footer)

    const mailToLink = `mailto:${mail}?subject=${subject}&body=${encodeURIComponent(
      body
    )}`

    return mailToLink
  }

  return (
    <Box
      className={classnames(
        styles.step,
        step < 2 ? styles.before : step > 2 ? styles.after : ''
      )}
    >
      <Box className={styles.wrapper}>
        <Flex
          className={styles.header}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex
            alignItems="center"
            as="button"
            onClick={() => setStep(1)}
            className={styles.navigationButton}
            aria-label="Go back"
          >
            <ArrowRight className={styles.arrowLeft} />
          </Flex>
          <Box
            marginLeft={[4]}
            className={styles.title}
          >{`Dela dina favoriter`}</Box>
          <Box width="20px" />
        </Flex>
        <Box>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingX={[4]}
          >
            <Box marginTop={[8]} width={1}>
              <Input
                className={styles.input}
                name="message"
                type="text"
                value={form.message}
                placeholder="Namnge din favoritlista"
                onChange={(e) => validateForm(e, 'message')}
              />
            </Box>

            <Box marginTop={[8]} width={1}>
              <Input
                className={classnames(
                  styles.input,
                  form.email && !form.validEmail ? styles.error : ''
                )}
                name="name"
                type="email"
                value={form.email}
                placeholder="E-postadress"
                onChange={(e) => validateForm(e, 'email', true)}
              />
            </Box>
            <Box marginTop={[10]}>
              <ButtonBorder
                onClick={() => {
                  setStep(3)
                  sendMail(
                    getMailtoLink(form.email, form.message, activeFavorites)
                  )
                  setForm({
                    message: '',
                    email: '',
                    validEmail: false,
                  })
                }}
                disabled={!form.message || !form.email || !form.validEmail}
              >
                Skicka
              </ButtonBorder>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default Step2

// extracted by mini-css-extract-plugin
export var close = "NewsletterPopup-module--close--JeZ4t";
export var content = "NewsletterPopup-module--content--nUGcR";
export var form = "NewsletterPopup-module--form--9mgjM";
export var gotImage = "NewsletterPopup-module--gotImage--J85sj";
export var hidden = "NewsletterPopup-module--hidden--3XEau";
export var imageWrapper = "NewsletterPopup-module--imageWrapper--uYJqn";
export var innerWrapper = "NewsletterPopup-module--innerWrapper--qa-sg";
export var label = "NewsletterPopup-module--label--dFVxI";
export var newsletterPopup = "NewsletterPopup-module--newsletterPopup--RP6rF";
export var title = "NewsletterPopup-module--title--0nLdg";
import * as React from 'react'
import classnames from 'classnames'

import * as styles from './Input.module.scss'
import { Props } from './types'

const Input = ({
  placeholder,
  name,
  type,
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  className,
  value,
  id,
}: Props): JSX.Element => (
  <input
    id={id}
    onChange={onChange}
    onBlur={onBlur}
    onFocus={onFocus}
    onKeyUp={onKeyUp}
    type={type}
    name={name}
    className={classnames(styles.input, className)}
    placeholder={placeholder}
    value={value}
  />
)

export default Input

import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'

import { ArrowRight, CheckMark } from 'src/components/UI/Icon/Icon'

import * as styles from '../../Favorites.module.scss'
import { Props } from './types'

const Step3 = ({ step, setStep }: Props): JSX.Element => {
  return (
    <Box className={classnames(styles.step, step < 3 ? styles.before : '')}>
      <Box className={styles.wrapper}>
        <Flex
          className={styles.header}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex
            alignItems="center"
            as="button"
            onClick={() => {
              setStep(1)
            }}
            aria-label="Go Back"
            className={styles.navigationButton}
          >
            <ArrowRight className={styles.arrowLeft} />
          </Flex>
          <Box
            marginLeft={[4]}
            className={styles.title}
          >{`Dela dina favoriter`}</Box>
          <Box width="20px" />
        </Flex>
        <Box>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingTop={[16]}
            paddingX={[25]}
            textAlign="center"
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              className={styles.badge}
            >
              <CheckMark />
            </Flex>
            <Box
              as="p"
              marginTop={[6]}
            >{`Kul att du har hittat några favoriter. Hoppas vi kan fortsätta vinspirera dig!`}</Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default Step3

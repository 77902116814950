import React from 'react'

type Icon = {
  color?: string
  bgColor?: string
  className?: string
  width?: number
  height?: number
  strokeWidth?: number
}

export const ArrowRight = ({
  color = '#000',
  className,
  width = 24,
  height = 20,
  strokeWidth = 1,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 20"
  >
    <path
      d="M13.09 2.182l7.637 7.636-7.636 7.636M20.727 9.818H0"
      stroke={color}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const Dash = ({
  color = '#000',
  className,
  width = 24,
  height = 20,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 2"
  >
    <path d="M0 2h16.457L18 0H1.543L0 2z" fill={color} />
  </svg>
)

export const ControlsIcon = ({
  color = '#000',
  className,
  width = 14,
  height = 14,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 14"
  >
    <rect
      x="9"
      y="13"
      width="5"
      height="1"
      rx=".5"
      transform="rotate(-90 9 13)"
      fill={color}
      stroke={color}
      strokeWidth=".2"
    />
    <rect
      x="1"
      y="3"
      width="12"
      height=".909"
      rx=".455"
      fill={color}
      stroke={color}
      strokeWidth=".2"
    />
    <rect
      x="4"
      y="6"
      width="5"
      height="1"
      rx=".5"
      transform="rotate(-90 4 6)"
      fill={color}
      stroke={color}
      strokeWidth=".2"
    />
    <rect
      x="1"
      y="10.093"
      width="12"
      height=".909"
      rx=".455"
      fill={color}
      stroke={color}
      strokeWidth=".2"
    />
  </svg>
)

export const ChevronDownIcon = ({
  color = '#000',
  className,
  width = 11,
  height = 10,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 10"
  >
    <path fill={color} d="M1.519 4.161l4.682 4.682-.52.52L.998 4.683z" />
    <path fill={color} d="M5.16 8.844l4.682-4.682.52.52-4.681 4.682z" />
  </svg>
)

export const EraseIcon = ({
  color = '#000',
  className,
  width = 10,
  height = 9,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 9"
  >
    <path
      d="M1.45.967l3.536 3.535m0 0L8.52 8.038M4.986 4.502L1.45 8.038m3.536-3.536L8.52.967"
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
)

export const DropdownArrow = ({
  color = '#000',
  className,
  width = 16,
  height = 8,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 16 8"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="m1 1 7 6 7-6"
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const PlusIcon = ({
  color = '#000',
  className,
  width = 14,
  height = 14,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      d="M7 1v12M1 7h12"
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
)

export const OneColumnIcon = ({
  color = '#000',
  className,
  width = 15,
  height = 15,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path fill={color} d="M0 0h15v15H0z" />
  </svg>
)

export const TwoColumnIcon = ({
  color = '#000',
  className,
  width = 15,
  height = 15,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    <path fill={color} d="M0 0h7v7H0zM0 8h7v7H0zM8 0h7v7H8zM8 8h7v7H8z" />
  </svg>
)

export const SearchIcon = ({
  color = '#000',
  className,
  width = 17,
  height = 17,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
  >
    <circle cx="7.192" cy="7.192" r="6.442" stroke={color} strokeWidth="1.5" />
    <path
      d="M12 12l4 4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const MenuIcon = ({
  color = '#000',
  className,
  width = 25,
  height = 13,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 13"
  >
    <path
      d="M1 1h23M1 12h23"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const CloseIcon = ({
  color = '#000',
  className,
  width = 20,
  height = 20,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      d="M1.48 1.52l8.486 8.485m0 0l8.485 8.486m-8.485-8.486L1.48 18.491m8.486-8.486L18.45 1.52"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const InfoIcon = ({
  color = '#000',
  bgColor = '#D9D1C5',
  className,
  width = 14,
  height = 14,
}: Icon): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <circle cx="7" cy="7" r="7" fill={bgColor} />
    <path
      d="M6.15 5.815v5.402h1.47V5.815H6.15zm-.025-.882h1.507V3.5H6.125v1.433z"
      fill={color}
    />
  </svg>
)

export const ChevronRight = ({
  color = '#000',
  className,
  width = 9,
  height = 18,
}: Icon): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 9 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 17L8 9L1 0.999999" stroke={color} />
  </svg>
)

export const ChevronLeft = ({
  color = '#000',
  className,
  width = 9,
  height = 18,
}: Icon): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 9 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 1L1 9L8 17" stroke={color} />
  </svg>
)

export const FacebookIcon = ({
  color = '#000',
  className,
  width = 10,
  height = 16,
}: Icon): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 16"
  >
    <path
      d="M8.971 8.93l.497-2.873H6.356V4.193c0-.786.434-1.553 1.828-1.553H9.6V.194S8.316 0 7.088 0C4.524 0 2.85 1.376 2.85 3.867v2.19H0V8.93h2.85v7.067c.745.004 2.958.004 3.506 0V8.93h2.615z"
      fill={color}
    />
  </svg>
)

export const InstagramIcon = ({
  color = '#000',
  className,
  width = 16,
  height = 16,
}: Icon): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
  >
    <path
      d="M11.235 0H4.768A4.771 4.771 0 000 4.768v6.467A4.77 4.77 0 004.768 16h6.467a4.773 4.773 0 004.768-4.768V4.768A4.775 4.775 0 0011.235 0zm3.156 11.235a3.158 3.158 0 01-3.156 3.156H4.768a3.158 3.158 0 01-3.156-3.156V4.768a3.158 3.158 0 013.156-3.156h6.467a3.158 3.158 0 013.156 3.156v6.467z"
      fill={color}
    />
    <path
      d="M8 3.862A4.142 4.142 0 003.862 8 4.142 4.142 0 008 12.138 4.142 4.142 0 0012.138 8 4.142 4.142 0 008 3.862zm0 6.667a2.53 2.53 0 110-5.058 2.53 2.53 0 010 5.058zM12.147 4.884a.99.99 0 100-1.98.99.99 0 000 1.98z"
      fill={color}
    />
  </svg>
)

export const LinkedInIcon = ({
  color = '#000',
  className,
  width = 14,
  height = 14,
}: Icon): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 93.06 93.06"
  >
    <path d="M11.185.08C5.004.08.001 5.092 0 11.259c0 6.173 5.003 11.184 11.186 11.184 6.166 0 11.176-5.011 11.176-11.184C22.362 5.091 17.351.08 11.185.08zM1.538 30.926h19.287V92.98H1.538zM69.925 29.383c-9.382 0-15.673 5.144-18.248 10.022h-.258v-8.479H32.92v62.053h19.27V62.281c0-8.093 1.541-15.932 11.575-15.932 9.89 0 10.022 9.256 10.022 16.451v30.178H93.06V58.942c0-16.707-3.605-29.559-23.135-29.559z" />
  </svg>
)

export const SearchBigIcon = ({
  color = '#000',
  className,
  width = 25,
  height = 25,
}: Icon): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
  >
    <circle
      cx="10.789"
      cy="10.789"
      r="10.039"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M18 18l6 6"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const BagWithCheckMark = ({
  color = '#000',
  className,
  width = 24,
  height = 24,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M21.936 23.5a.5.5 0 00.5-.5V4.143a.5.5 0 00-.235-.424L17.165.576A.5.5 0 0016.901.5H6.829a.5.5 0 00-.265.076L1.528 3.719a.5.5 0 00-.235.424V23a.5.5 0 00.5.5h20.143z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.793 4.143h21.03" stroke={color} />
    <path
      d="M8.987 13.886l2.698 2.828L16.18 12"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.865 1v3.143" stroke={color} />
  </svg>
)

export const Heart = ({
  color = '#000',
  className,
  width = 18,
  height = 16,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 16"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M9 16c-.256 0-.503-.093-.696-.261a208.07 208.07 0 00-2.047-1.76l-.003-.002c-1.813-1.544-3.38-2.877-4.469-4.19C.567 8.316 0 6.925 0 5.404c0-1.478.507-2.841 1.428-3.84A4.842 4.842 0 015.028 0a4.53 4.53 0 012.829.975c.422.327.805.727 1.143 1.193.338-.466.72-.866 1.143-1.193A4.53 4.53 0 0112.973 0c1.39 0 2.668.556 3.6 1.566C17.493 2.564 18 3.927 18 5.405c0 1.52-.567 2.913-1.785 4.381-1.09 1.314-2.655 2.647-4.469 4.19-.62.528-1.322 1.126-2.05 1.763-.193.168-.44.261-.696.261zM5.028 1.053A3.799 3.799 0 002.203 2.28c-.74.803-1.149 1.912-1.149 3.125 0 1.279.476 2.423 1.543 3.71 1.031 1.242 2.565 2.548 4.341 4.06l.003.003c.621.529 1.325 1.128 2.057 1.768a245.81 245.81 0 012.064-1.77c1.776-1.513 3.31-2.819 4.341-4.062 1.067-1.286 1.543-2.43 1.543-3.71 0-1.212-.408-2.321-1.149-3.124a3.799 3.799 0 00-2.825-1.227c-.8 0-1.535.255-2.184.756-.578.446-.98 1.011-1.216 1.406A.66.66 0 019 3.54a.66.66 0 01-.572-.325c-.236-.395-.638-.96-1.216-1.406a3.499 3.499 0 00-2.184-.756z"
      fill={color}
    />
  </svg>
)

export const HeartFilled = ({
  color = '#7D2B3A',
  className,
  width = 18,
  height = 16,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 16"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M9 16c-.256 0-.503-.093-.696-.261a208.07 208.07 0 00-2.047-1.76l-.003-.002c-1.813-1.544-3.38-2.877-4.469-4.19C.567 8.316 0 6.925 0 5.404c0-1.478.507-2.841 1.428-3.84A4.842 4.842 0 015.028 0a4.53 4.53 0 012.829.975c.422.327.805.727 1.143 1.193.338-.466.72-.866 1.143-1.193A4.53 4.53 0 0112.973 0c1.39 0 2.668.556 3.6 1.566C17.493 2.564 18 3.927 18 5.405c0 1.52-.567 2.913-1.785 4.381-1.09 1.314-2.655 2.647-4.469 4.19-.62.528-1.322 1.126-2.05 1.763-.193.168-.44.261-.696.261z"
      fill={color}
    />
  </svg>
)

export const MagnifyGlass = ({
  color = '#000',
  className,
  width = 16,
  height = 16,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={className}
    width={width}
    height={height}
  >
    <circle cx="6.5" cy="6.5" r="6" stroke={color} />
    <path d="M11 11l4 4" stroke={color} strokeLinecap="round" />
  </svg>
)

export const PrintIcon = ({
  color = '#000',
  className,
  width = 16,
  height = 16,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M11.769 7.461l-3.77 3.77L4.23 7.46M8 11.23V1M1 11.769v3.23h14v-3.23"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
)

export const TrashIcon = ({
  color = '#000',
  className,
  width = 12,
  height = 16,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 16"
    className={className}
    width={width}
    height={height}
  >
    <path
      stroke={color}
      strokeLinejoin="round"
      d="M1.5 4.5h9v11h-9zM.5 3.9a.6.6 0 00.6.6h9.8a.6.6 0 00.6-.6v-.8a.6.6 0 00-.6-.6H1.1a.6.6 0 00-.6.6v.8z"
    />
    <path
      d="M3.5 2a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V2z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path
      d="M4.363 7.125v6.125M7.637 7.125v6.125"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
)

export const CheckMark = ({
  color = '#000',
  className,
  width = 27,
  height = 20,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 20"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M2 9l8.5 8 15-15"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)

export const PlayIcon = ({
  color = '#000',
  className,
  width = 29,
  height = 31,
}: Icon): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 31"
    className={className}
    width={width}
    height={height}
  >
    <path
      d="M28 15.5L1 30V1l27 14.5z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)

import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'
import { useRecoilState } from 'recoil'

import { activeMenuState } from 'src/recoil/atoms'
import { SbLink } from 'src/storyblok/types/storyblokTypes'
import Highlight from 'src/storyblok/Components/Highlight/Highlight'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './NavigationDesktopContent.module.scss'
import { Props } from './types'

const NavigationDesktopContent = ({
  navigationGroups,
  closeNavItem,
  activeItem,
  fullSlug,
}: Props): JSX.Element => {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)

  return (
    <Flex
      className={
        activeItem > -1 && activeMenu === 'nav'
          ? [styles.wrapper, styles.active].join(' ')
          : styles.wrapper
      }
    >
      {navigationGroups?.length > 0 &&
        navigationGroups.map((group: any, index: number) => {
          const { link, columns, highlights } = group

          const { url, title } =
            link && link?.length > 0 ? getLinkAttributes(link[0]) : {}

          const threeColumns = columns?.length > 2
          const displayedHighlights = threeColumns
            ? highlights.slice(0, 2)
            : highlights

          return (
            <React.Fragment key={group._uid}>
              <Container
                paddingY={[6]}
                className={
                  activeItem === index
                    ? [styles.groupContent, styles.active].join(' ')
                    : styles.groupContent
                }
              >
                <Row>
                  {columns?.length > 0 &&
                    columns.map((column) => {
                      const { items } = column
                      return (
                        <Column width={[2 / 12]} key={column._uid}>
                          {items?.length > 0 &&
                            items.map((item: any | SbLink) => {
                              const { mark_last_link } = item

                              const link =
                                item.component === 'link'
                                  ? getLinkAttributes(item)
                                  : null
                              const links =
                                item.component === 'link_list' &&
                                item?.links.length > 0
                                  ? item.links
                                  : null

                              const linksTitle =
                                item && item.title ? item.title : ''

                              const linksUrl =
                                item &&
                                item.component === 'link_list' &&
                                item.link &&
                                item.link.cached_url
                                  ? item.link.cached_url
                                  : ''

                              return link ? (
                                <Box
                                  key={item._uid}
                                  className={styles.navigationLink}
                                  marginBottom={[6]}
                                >
                                  <LinkComponent
                                    onClick={() => {
                                      closeNavItem()
                                    }}
                                    to={link.url}
                                  >
                                    {link.title}
                                  </LinkComponent>
                                </Box>
                              ) : (
                                <Flex
                                  key={item._uid}
                                  flexDirection="column"
                                  marginBottom={[6]}
                                >
                                  {linksUrl ? (
                                    <Box
                                      className={styles.navigationLinksTitle}
                                      marginBottom={[2]}
                                    >
                                      <LinkComponent
                                        onClick={() => {
                                          closeNavItem()
                                        }}
                                        to={linksUrl}
                                        title={linksTitle}
                                      >
                                        {linksTitle}
                                      </LinkComponent>
                                    </Box>
                                  ) : linksTitle ? (
                                    <Box
                                      className={styles.navigationLinksTitle}
                                      marginBottom={[2]}
                                    >
                                      {linksTitle}
                                    </Box>
                                  ) : null}
                                  {links &&
                                    links.length > 0 &&
                                    links.map((link: SbLink, index: number) => {
                                      const { url, title } = link
                                        ? getLinkAttributes(link)
                                        : {}
                                      return (
                                        <Box
                                          key={link._uid}
                                          marginBottom={[
                                            index < links.length - 1
                                              ? '6px'
                                              : 0,
                                          ]}
                                        >
                                          <LinkComponent
                                            className={
                                              mark_last_link &&
                                              index === links.length - 1
                                                ? styles.underlineLink
                                                : ''
                                            }
                                            onClick={() => {
                                              closeNavItem()
                                            }}
                                            to={url}
                                          >
                                            {title}
                                          </LinkComponent>
                                        </Box>
                                      )
                                    })}
                                </Flex>
                              )
                            })}
                        </Column>
                      )
                    })}
                  <Column width={[threeColumns ? 6 / 12 : 8 / 12]}>
                    <Flex>
                      {displayedHighlights?.length > 0 &&
                        displayedHighlights.map((blok: any) => (
                          <Box
                            key={`grid-${blok._uid}`}
                            width={1}
                            marginLeft={[6]}
                          >
                            <Highlight
                              onClick={() => {
                                closeNavItem()
                              }}
                              key={blok._uid}
                              blok={blok}
                              type="fixed"
                            />
                          </Box>
                        ))}
                    </Flex>
                  </Column>
                </Row>
              </Container>
            </React.Fragment>
          )
        })}
    </Flex>
  )
}

export default NavigationDesktopContent

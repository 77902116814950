// extracted by mini-css-extract-plugin
export var active = "Favorites-module--active--fDIVT";
export var after = "Favorites-module--after--zukx1";
export var arrowLeft = "Favorites-module--arrowLeft--KvSC8";
export var badge = "Favorites-module--badge--NB5yO";
export var before = "Favorites-module--before--vG2ZH";
export var bottom = "Favorites-module--bottom---nQqC";
export var bottomButton = "Favorites-module--bottomButton--HT8Kq";
export var eraseAllButton = "Favorites-module--eraseAllButton--jAs4l";
export var error = "Favorites-module--error--5xeiz";
export var header = "Favorites-module--header--mwAwb";
export var input = "Favorites-module--input--5h60v";
export var list = "Favorites-module--list--8So+o";
export var navigationButton = "Favorites-module--navigationButton--EbPQH";
export var notice = "Favorites-module--notice--uCxGU";
export var sidebar = "Favorites-module--sidebar--Vk8gr";
export var step = "Favorites-module--step--wdzKy";
export var title = "Favorites-module--title--6UWHb";
export var wrapper = "Favorites-module--wrapper--5Z+Cg";
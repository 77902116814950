// extracted by mini-css-extract-plugin
export var bottom = "Search-module--bottom---7YNj";
export var content = "Search-module--content--rnKCE";
export var leftColumn = "Search-module--leftColumn--doAVR";
export var leftSection = "Search-module--leftSection--Jddp6";
export var linkResult = "Search-module--linkResult--YPymi";
export var loading = "Search-module--loading--oT8WP";
export var message = "Search-module--message--B5kFQ";
export var results = "Search-module--results--wYvZg";
export var title = "Search-module--title--lFAS1";
export var withBorder = "Search-module--withBorder--eQn8p";
export var wrapper = "Search-module--wrapper--A09wx";
// extracted by mini-css-extract-plugin
export var active = "NavigationMobile-module--active--Tb5Iw";
export var group = "NavigationMobile-module--group--77YL4";
export var groupContent = "NavigationMobile-module--groupContent--jTtFV";
export var groupTitle = "NavigationMobile-module--groupTitle--Qn0Tg";
export var header = "NavigationMobile-module--header--gYMeh";
export var highlights = "NavigationMobile-module--highlights--LA--O";
export var linkList = "NavigationMobile-module--linkList--bIYVW";
export var logotype = "NavigationMobile-module--logotype--OQcUH";
export var menuButton = "NavigationMobile-module--menuButton--sCJGP";
export var navigation = "NavigationMobile-module--navigation--KRo5g";
export var navigationLink = "NavigationMobile-module--navigationLink--quH2N";
export var navigationLinks = "NavigationMobile-module--navigationLinks--jDn9D";
export var navigationLinksTitle = "NavigationMobile-module--navigationLinksTitle--2xgTa";
export var plusIcon = "NavigationMobile-module--plusIcon--LRDU7";
export var showAllButton = "NavigationMobile-module--showAllButton--+TqrQ";
export var text = "NavigationMobile-module--text--O6DL-";
export var underlineLink = "NavigationMobile-module--underlineLink--62dXl";
export var wrapper = "NavigationMobile-module--wrapper--nB7mS";
// extracted by mini-css-extract-plugin
export var active = "GuidePopup-module--active--VQEdT";
export var badge = "GuidePopup-module--badge---Wp7t";
export var close = "GuidePopup-module--close---iyMX";
export var lightGreen = "GuidePopup-module--lightGreen--T0JWv";
export var modal = "GuidePopup-module--modal--yAflr";
export var overlay = "GuidePopup-module--overlay--L33dl";
export var rose = "GuidePopup-module--rose--HUYQj";
export var sand = "GuidePopup-module--sand--zApY8";
export var subTitle = "GuidePopup-module--subTitle--ZkCJL";
export var title = "GuidePopup-module--title--fyr6Z";
export var wrapper = "GuidePopup-module--wrapper--b7vsE";
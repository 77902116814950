import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import MailchimpForm from 'src/components/MailchimpForm/MailchimpForm'

import * as styles from './Newsletter.module.scss'
import { Props } from './types'

const listDefault =
  'https://wardwines.us1.list-manage.com/subscribe/post?u=c3ae07bf83d2709cd54acc0a2&amp;id=dbbe41f3c8'
const listPremium =
  'https://wardwines.us1.list-manage.com/subscribe/post?u=c3ae07bf83d2709cd54acc0a2&amp;id=489c9d8f07'

const Newsletter = ({ blok }: Props): JSX.Element => {
  const { label, title, subtitle, policy, button, list } = blok
  const texts = { label, title, policy, button }

  const mailchimpList = list === 'premium' ? listPremium : listDefault
  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper} paddingY={[18, null, null, 15]}>
        <Box paddingX={[6, null, null, 6]}>
          <Row
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Column width={[1, null, null, 4 / 12]}>
              {label && (
                <Box className={styles.label} marginBottom={[4, null, null, 4]}>
                  {label}
                </Box>
              )}
              {title && (
                <Box className={styles.title} marginBottom={[8, null, null, 8]}>
                  {title}
                </Box>
              )}
              {subtitle && (
                <Box
                  className={styles.subtitle}
                  marginBottom={[8, null, null, 8]}
                >
                  {subtitle}
                </Box>
              )}

              <Box>
                <MailchimpForm
                  url={mailchimpList}
                  use={{ firstName: false, lastName: false }}
                  texts={texts}
                  isPremium={list === 'premium'}
                />
              </Box>
            </Column>
          </Row>
        </Box>
      </Container>
    </SbEditable>
  )
}

export default Newsletter

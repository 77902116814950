import React from 'react'
import { Flex, Box } from 'reflexbox'
import { useRecoilState } from 'recoil'

import { favoritesState, activePopupState } from 'src/recoil/atoms'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import ButtonFilled from 'src/components/UI/Button/ButtonFilled/ButtonFilled'
import { TrashIcon } from 'src/components/UI/Icon/Icon'
import { getUpdatedFavoritesArray } from 'src/helpers/favoriteHelpers'

import * as styles from './ProductResult.module.scss'
import { Props } from './types'

const ProductResult = ({
  product,
  onClick,
  isFavorite,
}: Props): JSX.Element => {
  const [activeFavorites, setActiveFavorites] = useRecoilState(favoritesState)
  const [, setActivePopup] = useRecoilState(activePopupState)

  const { name, price, slug, media, sku, status, url } = product

  const thumbnail = media && media.length > 0 ? media[0] : null

  const addToFavorite = (item) => {
    setActiveFavorites(getUpdatedFavoritesArray(item, activeFavorites))
  }

  return (
    <Flex
      paddingY={[2, null, null, 2]}
      alignItems="center"
      className={styles.wrapper}
    >
      <LinkComponent to={slug ? slug : ''} title={name} onClick={onClick}>
        <Flex alignItems="center">
          <Box className={styles.imageWrapper}>
            <Box className={styles.image}>
              {thumbnail && (
                <Image
                  src={thumbnail}
                  alt={name ? name : ''}
                  sizes={{
                    mobile: '50vw',
                    tablet: '33vw',
                    desktop: '33vw',
                  }}
                />
              )}
            </Box>
          </Box>
          <Flex flexDirection="column" marginLeft={[4, null, null, 4]}>
            {name && (
              <Box
                as="span"
                className={styles.name}
                marginBottom={['2px', null, null, '2px']}
              >
                {name}
              </Box>
            )}
            {price && (
              <Box as="span" className={styles.price}>
                {price} sek
              </Box>
            )}
            {sku && (
              <Box as="span" className={styles.sku}>
                {sku ? `Art.nr. ${sku}` : ``}
              </Box>
            )}
          </Flex>
        </Flex>
      </LinkComponent>
      {isFavorite && (
        <Flex marginLeft="auto" alignItems="center" paddingLeft={2}>
          <ButtonFilled
            className={styles.button}
            to={status === 'available' && url ? '' : slug ? slug : ''}
            onClick={
              status === 'available' && url
                ? () => setActivePopup({ url, name, price })
                : onClick
            }
            title={name}
          >
            {status === 'available' && url ? 'Köp' : 'Info'}
          </ButtonFilled>
          <Flex
            paddingLeft={'8px !important'}
            alignItems="center"
            className={styles.erase}
            as="button"
            title="Ta bort"
            onClick={() =>
              addToFavorite({
                sku,
                name,
                price,
                media,
                slug,
                status,
              })
            }
          >
            <TrashIcon />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default ProductResult

import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'
import { useLocation } from '@reach/router'

import {
  PlusIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
} from 'src/components/UI/Icon/Icon'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import Markdown from 'src/components/Markdown/Markdown'
import ImageWithLink from 'src/storyblok/Components/ImageWithLink/ImageWithLink'
import LogoMarquee from 'src/storyblok/Modules/LogoMarquee/LogoMarquee'
import { gtagEvent } from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'

import Newsletter from './Newsletter/Newsletter'
import * as styles from './Footer.module.scss'
import { Props } from './types'

const Footer = ({ blok }: Props): JSX.Element => {
  const {
    about_title,
    about_text,
    about_images,
    shop_title,
    shop_links,
    info_title,
    info_links,
    contact_title,
    contact_text,
    social_title,
    social_text,
    social_links,
    newsletter_label,
    newsletter_title,
    newsletter_subtitle,
    newsletter_policy,
    newsletter_button,
    marque_logotypes,
  } = blok

  const [activeColumn, setActiveColumn] = useState('')
  const locationHref = useLocation ? useLocation().href : ''

  const handleLinkClick = () => {
    const CF = window?.CookieFirst || null

    if (CF) {
      CF.openPanel()
    }
  }

  return (
    <SbEditable content={blok}>
      <Flex
        className={styles.printBlock}
        justifyContent="center"
        alignItems="center"
      >
        <Box>Ward Wines AB</Box>
        <Box>Östermalmsgatan 87D, 114 59 Stockholm</Box>
        <Box>info@wardwines.se</Box>
        <Box>wardwines.se</Box>
      </Flex>
      <Box className="hidePrint">
        {marque_logotypes && marque_logotypes.length > 0 && (
          <LogoMarquee logotypes={marque_logotypes} />
        )}
      </Box>
      <Box className="hidePrint">
        <Newsletter
          blok={{
            label: newsletter_label,
            title: newsletter_title,
            subtitle: newsletter_subtitle,
            policy: newsletter_policy,
            button: newsletter_button,
          }}
        />
      </Box>
      <Container
        className={classnames(styles.wrapper, 'hidePrint')}
        paddingTop={[0, null, null, 12]}
        paddingBottom={[6, null, null, 14]}
      >
        <Row>
          <Column width={[1, null, null, 4 / 12]}>
            <Box
              className={classnames(
                styles.column,
                activeColumn === 'about' ? styles.active : ''
              )}
            >
              {about_title && (
                <Box
                  as="button"
                  onClick={() =>
                    setActiveColumn(activeColumn === 'about' ? '' : 'about')
                  }
                  className={classnames(styles.title, styles.button)}
                  marginBottom={[0, null, null, 4]}
                >
                  {about_title}

                  <PlusIcon className={styles.icon} />
                </Box>
              )}

              <Box
                paddingBottom={[3, null, null, 0]}
                className={styles.content}
              >
                {about_text && <Markdown source={about_text} />}
                {about_images?.length > 0 && (
                  <Flex
                    justifyContent="flex-start"
                    marginTop={[4, null, null, 6]}
                    marginBottom={[4, null, null, 0]}
                  >
                    {about_images?.length > 0 &&
                      about_images.map((blok: any) => (
                        <Box
                          width="auto"
                          key={blok._uid}
                          marginRight={[6, null, null, 6]}
                        >
                          <ImageWithLink
                            className={styles.logotype}
                            blok={blok}
                          />
                        </Box>
                      ))}
                  </Flex>
                )}
              </Box>
            </Box>
          </Column>
          <Column width={[1, null, null, 2 / 12]}>
            <Box
              className={classnames(
                styles.column,
                activeColumn === 'shop' ? styles.active : ''
              )}
            >
              {shop_title && (
                <Box
                  as="button"
                  onClick={() =>
                    setActiveColumn(activeColumn === 'shop' ? '' : 'shop')
                  }
                  className={classnames(styles.title, styles.button)}
                  marginBottom={[0, null, null, 4]}
                >
                  {shop_title}

                  <PlusIcon className={styles.icon} />
                </Box>
              )}
              <Box
                paddingBottom={[3, null, null, 0]}
                className={styles.content}
              >
                {shop_links?.length > 0 && (
                  <Box>
                    {shop_links.map((link) => {
                      const { url, title } = link ? getLinkAttributes(link) : {}
                      return url && title ? (
                        <Box key={link._uid} marginBottom={[3, null, null, 3]}>
                          <LinkComponent to={url}>{title}</LinkComponent>
                        </Box>
                      ) : null
                    })}
                  </Box>
                )}
              </Box>
            </Box>
          </Column>
          <Column width={[1, null, null, 2 / 12]}>
            <Box
              className={classnames(
                styles.column,
                activeColumn === 'info' ? styles.active : ''
              )}
            >
              {info_title && (
                <Box
                  as="button"
                  onClick={() =>
                    setActiveColumn(activeColumn === 'info' ? '' : 'info')
                  }
                  className={classnames(styles.title, styles.button)}
                  marginBottom={[0, null, null, 4]}
                >
                  {info_title}

                  <PlusIcon className={styles.icon} />
                </Box>
              )}
              <Box
                paddingBottom={[3, null, null, 0]}
                className={styles.content}
              >
                {info_links?.length > 0 && (
                  <Box>
                    {info_links.map((link) => {
                      const { url, title } = link ? getLinkAttributes(link) : {}
                      return url && title ? (
                        <Box key={link._uid} marginBottom={[3, null, null, 3]}>
                          <LinkComponent to={url}>{title}</LinkComponent>
                        </Box>
                      ) : null
                    })}
                    <Box marginBottom={[3, null, null, 3]}>
                      <Box
                        as="button"
                        className={styles.cookieSettings}
                        onClick={() => handleLinkClick()}
                      >{`Cookieinställningar`}</Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Column>
          <Column width={[1, null, null, 2 / 12]}>
            <Box
              className={classnames(
                styles.column,
                activeColumn === 'contact' ? styles.active : ''
              )}
            >
              {contact_title && (
                <Box
                  as="button"
                  onClick={() =>
                    setActiveColumn(activeColumn === 'contact' ? '' : 'contact')
                  }
                  className={classnames(styles.title, styles.button)}
                  marginBottom={[0, null, null, 4]}
                >
                  {contact_title}
                  <PlusIcon className={styles.icon} />
                </Box>
              )}
              <Box
                paddingBottom={[3, null, null, 0]}
                className={styles.content}
              >
                {contact_text && <Markdown source={contact_text} />}
              </Box>
            </Box>
          </Column>
          <Column width={[1, null, null, 2 / 12]}>
            {social_title && (
              <Box
                className={styles.title}
                marginTop={[7, null, null, 0]}
                marginBottom={[4, null, null, 4]}
              >
                {social_title}
              </Box>
            )}
            {social_text && (
              <Box marginBottom={[4, null, null, 4]}>
                <Markdown source={social_text} />
              </Box>
            )}
            {social_links?.length > 0 && (
              <Flex flexWrap="wrap">
                {social_links.map((social) => {
                  const { social_media, url } = social

                  let title = ''
                  let Icon = null
                  switch (social_media) {
                    case 'facebook':
                      title = 'Facebook'
                      Icon = <FacebookIcon />
                      break
                    case 'instagram':
                      title = 'Instagram'
                      Icon = <InstagramIcon />
                      break
                    case 'linkedin':
                      title = 'Instagram'
                      Icon = <LinkedInIcon />
                      break
                    default:
                      title = ''
                      break
                  }

                  return url && title ? (
                    <Box
                      key={url}
                      marginBottom={[3, null, null, 3]}
                      marginRight={[3, null, null, 3]}
                    >
                      <LinkComponent
                        className={styles.socialLink}
                        to={url}
                        title={title}
                        onClick={() =>
                          gtagEvent({
                            action: title,
                            category: 'Social Share',
                            label: locationHref,
                          })
                        }
                      >
                        {Icon}
                      </LinkComponent>
                    </Box>
                  ) : null
                })}
              </Flex>
            )}
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Footer

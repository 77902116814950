import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'
import { useRecoilState } from 'recoil'
import isNode from 'is-node'

import ProductResult from 'src/storyblok/GlobalModules/Header/Search/ProductResult/ProductResult'
import ButtonSecondary from 'src/components/UI/Button/ButtonSecondary/ButtonSecondary'
import ButtonBorder from 'src/components/UI/Button/ButtonBorder/ButtonBorder'
import { Heart, CloseIcon } from 'src/components/UI/Icon/Icon'
import { favoritesState, activeMenuState } from 'src/recoil/atoms'

import * as styles from '../../Favorites.module.scss'
import { Props } from './types'

const Step1 = ({ step, setStep }: Props): JSX.Element => {
  const [activeFavorites, setActiveFavorites] = useRecoilState(favoritesState)
  const [, setActiveMenu] = useRecoilState(activeMenuState)
  return (
    <Box className={classnames(styles.step, step !== 1 ? styles.after : '')}>
      <div className={styles.wrapper}>
        <Flex
          className={styles.header}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Heart width={20} height={20} />
            <Box
              marginLeft={[4]}
              className={styles.title}
            >{`Dina favoriter (${activeFavorites.length})`}</Box>
          </Flex>
          <Flex
            alignItems="center"
            as="button"
            className={styles.navigationButton}
            padding={[1]}
            marginRight="-4px"
            aria-label="Close menu"
            onClick={() => setActiveMenu('')}
          >
            <CloseIcon />
          </Flex>
        </Flex>
        {!isNode &&
          (activeFavorites?.length > 0 ? (
            <div>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                paddingTop={[0, null, null, 1]}
                paddingX={[4, null, null, 6]}
              >
                <Box className={styles.list} width={1}>
                  {activeFavorites.map((product) => {
                    return (
                      <ProductResult
                        key={product.slug}
                        onClick={() => setActiveMenu('')}
                        product={{
                          name: product.name,
                          price: product.price,
                          slug: product.slug,
                          media: [product.image],
                          sku: product.sku,
                          status: product.status,
                          url: product.url,
                        }}
                        isFavorite
                      />
                    )
                  })}
                  <Flex width={1} justifyContent="flex-end" marginTop={4}>
                    <Box
                      className={styles.eraseAllButton}
                      as="button"
                      onClick={() => setActiveFavorites([])}
                    >
                      Rensa alla
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </div>
          ) : (
            <div>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                paddingTop={[14, null, null, 16]}
                paddingX={[6, null, null, 25]}
                textAlign="center"
              >
                <Box
                  as="p"
                  marginBottom={[6]}
                >{`Inga favoriter än? Utforska och bli inspirerad!`}</Box>
                <ButtonBorder onClick={() => setActiveMenu('')} to="/shoppen">
                  Utforska
                </ButtonBorder>
              </Flex>
            </div>
          ))}
      </div>
      {activeFavorites?.length > 0 && (
        <Box className={styles.bottom} padding={[4]}>
          <Box
            as="p"
            marginBottom={2}
            className={styles.notice}
          >{`Systembolaget tillåter inte att en varukorg med hela favoritlistan skapas, vilket gör att du länkas till varje vin separat. Spara genom att mejla favoritlistan.`}</Box>

          <ButtonSecondary
            className={styles.bottomButton}
            onClick={() => setStep(2)}
          >
            {`Mejla listan`}
          </ButtonSecondary>
        </Box>
      )}
    </Box>
  )
}

export default Step1

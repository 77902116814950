const parseStoryblokProductToAlgolia = (product, dataSources) => {
  const {
    getDataSourceEntryName: getDataSourceEntryNameTemp,
    getDataSourceEntriesByValues: getDataSourceEntriesByValuesTemp,
  } = require('../helpers/getDataSourceData')

  getDataSourceEntryName = getDataSourceEntryNameTemp
  getDataSourceEntriesByValues = getDataSourceEntriesByValuesTemp

  return {
    objectID: product.uuid,
    ...product.content,
    name: product.name,
    id: product.uuid,
    slug: product.full_slug,
    media:
      product.content &&
      product.content.media &&
      product.content.media.length > 0
        ? product.content.media.map((img) => img.filename)
        : [],
    price:
      product.content && product.content.price
        ? parseInt(product.content.price)
        : 0,
    categoryName:
      getDataSourceEntryName &&
      product.content &&
      product.content.category &&
      product.content.category.length > 0
        ? product.content.category.map((cat) =>
            getDataSourceEntryName(cat, dataSources)
          )
        : [], // Multiple data source
    producerName:
      getDataSourceEntryName && product.content
        ? getDataSourceEntryName(product.content.producer, dataSources)
        : '', // Single data source
    countryName:
      getDataSourceEntryName && product.content
        ? getDataSourceEntryName(product.content.country, dataSources)
        : '', // Single data source
    regionName:
      getDataSourceEntryName &&
      product.content &&
      product.content.region &&
      product.content.region.length > 0
        ? product.content.region.map((reg) =>
            getDataSourceEntryName(reg, dataSources)
          )
        : [], // Multiple data source
    grapeName:
      getDataSourceEntryName &&
      product.content &&
      product.content.grape &&
      product.content.grape.length > 0
        ? product.content.grape.map((grape) =>
            getDataSourceEntryName(grape, dataSources)
          )
        : [], // Multiple data source
    assortmentName:
      getDataSourceEntryName && product.content
        ? getDataSourceEntryName(product.content.assortment)
        : '', // Single data source
    inStockProduct:
      product.content &&
      product.content.assortment &&
      product.content.assortment === 'fast_sortiment'
        ? true
        : false,
    orderAssortment:
      product.content &&
      product.content.assortment &&
      product.content.assortment === 'bestallningssortiment'
        ? true
        : false,
    labelNames:
      getDataSourceEntriesByValues &&
      product.content &&
      product.content.labels &&
      product.content.labels.length > 0
        ? getDataSourceEntriesByValues(
            product.content.labels,
            'labels',
            dataSources
          )
        : [], // Multiple data sources
    goesWellWithNames:
      getDataSourceEntriesByValues &&
      product.content &&
      product.content.goes_well_with &&
      product.content.goes_well_with.length > 0
        ? getDataSourceEntriesByValues(
            product.content.goes_well_with,
            'goes-well-with',
            dataSources
          )
        : [], // Multiple data sources
    relatedSizes:
      product.content &&
      product.content.related_sizes &&
      product.content.related_sizes.length > 0
        ? product.content.related_sizes.map((size) => ({
            slug: size.full_slug,
            size:
              size && size.content && size.content.volume
                ? size.content.volume
                : '',
          }))
        : [],
  }
}

const parseStoryblokArticleToAlgolia = (article, dataSources) => {
  const {
    getDataSourceEntryName: getDataSourceEntryNameTemp,
    getDataSourceEntriesByValues: getDataSourceEntriesByValuesTemp,
  } = require('../helpers/getDataSourceData')

  getDataSourceEntryName = getDataSourceEntryNameTemp
  getDataSourceEntriesByValues = getDataSourceEntriesByValuesTemp

  return {
    objectID: article.uuid,
    ...article.content,
    content:
      article &&
      article.content &&
      article.content.content &&
      article.content.content.length > 0
        ? article.content.content.map((module) => ({
            title: module.title,
            preparation: module.preparation,
            ingredients: module.ingredients,
            products: module.products
              ? module.products.map((product) => product.name)
              : [],
          }))
        : { title: '', preparation: '', ingredients: '', products: [] },
    id: article.uuid,
    slug: article.full_slug,
    thumbnail:
      article &&
      article.content &&
      article.content.image &&
      article.content.image.filename
        ? article.content.image.filename
        : '',
    label:
      article && article.content && article.content.label
        ? article.content.label
        : '',
    title:
      article && article.content && article.content.title
        ? article.content.title
        : '',
    preamble:
      article && article.content && article.content.preamble
        ? article.content.preamble
        : '',
    categoryName:
      article &&
      article.content &&
      article.content.category &&
      getDataSourceEntryName
        ? getDataSourceEntryName(article.content.category, dataSources)
        : '', // Single data source
    tagNames:
      getDataSourceEntryName &&
      article &&
      article.content &&
      article.content.tags &&
      article.content.tags.length > 0
        ? article.content.tags.map((tag) =>
            getDataSourceEntryName(tag, dataSources)
          )
        : [], // Multiple data sources
  }
}

module.exports = {
  parseStoryblokProductToAlgolia,
  parseStoryblokArticleToAlgolia,
}

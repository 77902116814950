import React, { FC, useRef, useState, useEffect } from 'react'
import classnames from 'classnames'

import { AssetStoryblok } from 'src/types/sbTypes'

import * as styles from './StoryblokVideo.module.scss'

export const getMediaType = (filename: string) => {
  if (!filename) {
    return ''
  }
  let mediaType = ''
  const extension = filename ? filename.split('.').pop() : ''

  switch (extension) {
    case 'mp4':
    case 'mov':
    case 'm4v':
    case 'wmv':
    case 'flv':
    case 'divx':
    case 'avi':
      mediaType = 'video'
      break
    case 'jpg':
    case 'JPG':
    case 'jpeg':
    case 'JPEG':
    case 'gif':
    case 'png':
    case 'avif':
    case 'svg':
      mediaType = 'image'
      break
  }
  return mediaType
}

interface Props {
  src: any
  posterImage?: AssetStoryblok
  className?: string
  fill?: boolean | undefined
  autoplay?: boolean | undefined
}

const StoryblokVideo: FC<Props> = ({ src, className, fill, autoplay }) => {
  const isVideo = getMediaType(src) === 'video'

  let srcIsLoaded = false
  const [hasStarted, setHasStarted] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  /* eslint-disable */
  const videoElement: any = useRef(null)
  /* eslint-enable */

  const togglePlay = () => {
    if (videoElement?.current) {
      isPlaying ? videoElement.current.pause() : videoElement.current.play()
    }
  }

  const onPlay = () => {
    togglePlay()
    if (!hasStarted) {
      setHasStarted(true)
      if (videoElement?.current) {
        videoElement.current.style.opacity = 1
      }
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      const isIntersecting = entry?.isIntersecting
        ? entry.isIntersecting
        : false

      if (videoElement?.current) {
        if (isVideo && !hasStarted && isIntersecting) {
          if (!srcIsLoaded) {
            videoElement.current.setAttribute('src', src)
            srcIsLoaded = true
          }
          videoElement.current.onloadeddata = () => {
            if (autoplay) {
              setHasStarted(true)
              setIsPlaying(true)

              videoElement.current.play()
              videoElement.current.style.opacity = 1
            }
          }
        }
      }
    })

    if (observer && videoElement?.current) {
      observer.observe(videoElement.current)
      return () => {
        observer.disconnect()
      }
    }
  }, [hasStarted])

  if (isVideo) {
    return (
      <div
        className={classnames(
          styles.wrapper,
          hasStarted ? styles.hasStarted : '',
          fill ? styles.fill : '',
          className
        )}
        onClick={() => onPlay()}
      >
        {/* {!autoplay && poster_image?.filename && (
          <StoryblokImage image={poster_image} className={styles.posterImage} />
        )} */}
        <video
          ref={videoElement}
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          src=""
        >
          <source src="" type="video/mp4" />
        </video>
      </div>
    )
  } else {
    return <div className={styles.placeholder}>{'uploadSupported'}</div>
  }
}

export default StoryblokVideo

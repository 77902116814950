import React from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './ButtonPrimary.module.scss'
import { Props } from './types'

const ButtonContent = ({ children }: Props): JSX.Element => (
  <Flex alignItems="center">
    <Box className={styles.text} textAlign="left">
      {children}
    </Box>
  </Flex>
)

const ButtonPrimary = ({
  onClick,
  to,
  title,
  children,
  disabled = false,
  className = '',
}: Props): JSX.Element => {
  const buttonStyles = classnames(
    styles.button,
    disabled ? styles.disabled : '',
    className
  )
  return onClick && !to ? (
    <Flex
      as="button"
      disabled={disabled}
      onClick={onClick}
      className={buttonStyles}
    >
      <ButtonContent>{children}</ButtonContent>
    </Flex>
  ) : to ? (
    <LinkComponent
      to={to}
      onClick={onClick}
      title={title}
      className={buttonStyles}
    >
      <ButtonContent>{children}</ButtonContent>
    </LinkComponent>
  ) : (
    <div />
  )
}

export default ButtonPrimary

import React, { useState } from 'react'
import { Box } from 'reflexbox'
import { useRecoilState } from 'recoil'
import isNode from 'is-node'
import classnames from 'classnames'

import { activeMenuState } from 'src/recoil/atoms'

import Step1 from './Steps/Step1/Step1'
import Step2 from './Steps/Step2/Step2'
import Step3 from './Steps/Step3/Step3'
import * as styles from './Favorites.module.scss'
import { Props } from './types'

const Favorites = (props: Props): JSX.Element => {
  const [activeMenu] = useRecoilState(activeMenuState)

  const [step, setStep] = useState(1)
  return (
    <React.Fragment>
      <Box
        className={classnames(
          styles.sidebar,
          activeMenu === 'favorites' ? styles.active : '',
          'hidePrint'
        )}
      >
        <div>
          <Step1 step={step} setStep={setStep} />
        </div>
        {!isNode && (
          <React.Fragment>
            <Step2 step={step} setStep={setStep} />
            <Step3 step={step} setStep={setStep} />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  )
}

export default Favorites

/* $: yarn add -D @types/gtag.js */

import React, { useEffect } from 'react'
import { globalHistory } from '@reach/router'
import { useRecoilState } from 'recoil'

import { userActivityState } from 'src/recoil/atoms'

const GA_UA_CODE = process.env.GATSBY_GA_UA_CODE
const GA_4_CODE = process.env.GATSBY_GA_4_CODE

interface eventProps {
  action: string
  category: string
  label: string
  value?: number | string
}
interface viewProps {
  title: string
  location: string
  path: string
  dimension1?: string
}
interface searchProps {
  search_term: string
  search_click: string
}

interface wishlistProps {
  value: number | string
  item: { item_id: string; item_name: string; price: number | string }
}

declare global {
  interface Window {
    gtag: any
  }
}

export default function GoogleAnalytics() {
  const [, setUserActivity] = useRecoilState(userActivityState)
  useEffect(() => {
    const pageView = (url: string) => {
      if (window && window.gtag) {
        window.gtag('set', 'page_path', url)
        window.gtag('event', 'page_view')
      }
    }

    const handleRouteChange = (url: string) => {
      // Register page view to anlaytics.
      pageView(url)
      // Reset user activity event data
      console.log('reset user activity')
      setUserActivity({ hasScrolled: false, hasStayed: false })
    }
    const removeListener = globalHistory.listen((params) => {
      const { location } = params
      if (location?.pathname) {
        handleRouteChange(location.pathname)
      }
    })
    return () => {
      removeListener()
    }
  }, [globalHistory.listen])

  return <div />
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = ({ action, category, label, value }: eventProps) => {
  if (window && window.gtag) {
    window.gtag('event', category === 'Click - Buy' ? category : action, {
      event_category: category,
      event_action: action,
      event_label: label,
      value: value,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagUAPageView = ({
  title,
  location,
  path,
  dimension1,
}: viewProps) => {
  if (window && window.gtag && GA_UA_CODE) {
    window.gtag('event', 'page_view', {
      page_title: title,
      page_location: location,
      page_path: path,
      dimension1: dimension1,
      send_to: GA_UA_CODE,
    })
  }
}

export const gtagGA4Search = ({ search_term, search_click }: searchProps) => {
  if (window && window.gtag && GA_4_CODE) {
    window.gtag('event', 'search', {
      search_term: search_term,
      search_click: search_click,
      send_to: GA_4_CODE,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_to_wishlist
export const gtagAddToWishlist = ({ item, value }: wishlistProps) => {
  if (window && window.gtag) {
    window.gtag('event', 'add_to_wishlist', {
      currency: 'SEK',
      value: value,
      items: [
        {
          ...item,
          quantity: 1,
          // affiliation: 'Google Merchandise Store',
          // coupon: 'SUMMER_FUN',
          // currency: 'USD',
          // discount: 2.22,
          // index: 0,
          // item_brand: 'Google',
          // item_category: 'Apparel',
          // item_category2: 'Adult',
          // item_category3: 'Shirts',
          // item_category4: 'Crew',
          // item_category5: 'Short sleeve',
          // item_list_id: 'related_products',
          // item_list_name: 'Related Products',
          // item_variant: 'green',
          // location_id: 'L_12345',
        },
      ],
    })
  }
}

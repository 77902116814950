import React from 'react'

import Layout from 'src/components/Layout/Layout'
import Wrapper from 'src/components/Wrapper/Wrapper'
import 'src/styles/global.scss'
import BrowserDetector from 'src/helpers/browserDetector'

export const wrapRootElement = Wrapper

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

export const onClientEntry = async () => {
  // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    // console.log(`👍 IntersectionObserver is polyfilled`)
  }

  if (document) {
    const htmlEL = document.getElementsByTagName('html')[0]

    // Add browser class
    const detector = new BrowserDetector()

    if (detector.browserName) {
      htmlEL.classList.add(`browser-${detector.browserName.toLowerCase()}`)
    }
  }
}

import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import Marquee from 'react-fast-marquee'

import Image from 'src/components/UI/Image/Image'

import * as styles from './LogoMarquee.module.scss'
import { Props } from './types'

const renderItems = (items: any[]) =>
  items.map((item: any, index: number) => (
    <Flex alignItems={'center'} key={index} marginX={8}>
      <Image
        src={item.image.filename}
        alt={item.image.title}
        sizes={{
          mobile: '200px',
          tablet: '200px',
          desktop: '200px',
        }}
        isCritical={true}
      />
    </Flex>
  ))

const LogoMarquee = ({ logotypes }: Props): JSX.Element => {
  const [load, setLoad] = useState(false)
  const firstHalf: any[] = []
  const lastHalf: any[] = []
  const elRef = useRef(null)

  logotypes.forEach((item: any, index: number) => {
    if (index % 2 === 0) {
      firstHalf.push(item)
    } else {
      lastHalf.push(item)
    }
  })

  let observer: any = null

  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && observer && !load) {
        setLoad(true)
      }
    }
  }

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined' && !observer) {
      observer = new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 500px 0px',
      })

      if (elRef && elRef.current) {
        observer.observe(elRef.current)
      }
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Flex
      paddingY={[4, null, null, 3]}
      alignItems="center"
      ref={elRef}
      minHeight={['345px', null, null, '180px']}
      speed={logotypes?.length > 0 ? 500 / logotypes.length : 1000}
      className={styles.wrapper}
    >
      {load && (
        <Flex flexDirection="column" className={styles.mobile}>
          <Marquee gradient={false} speed={20}>
            {renderItems(firstHalf)}
          </Marquee>
          <Box marginTop={4}>
            <Marquee delay={1} gradient={false} speed={22}>
              {renderItems(lastHalf)}
            </Marquee>
          </Box>
        </Flex>
      )}
      {load && (
        <Box className={styles.desktop}>
          <Marquee gradient={false} speed={30}>
            {renderItems(logotypes)}
          </Marquee>
        </Box>
      )}
    </Flex>
  )
}

export default LogoMarquee

import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useRecoilState } from 'recoil'
import classnames from 'classnames'

import { activeMenuState } from 'src/recoil/atoms'
import {
  CloseIcon,
  BagWithCheckMark,
  Heart,
  MagnifyGlass,
} from 'src/components/UI/Icon/Icon'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './GuidePopup.module.scss'
import { Props } from './types'

const GuidePopup = ({ blok }: Props): JSX.Element => {
  const {
    title,
    text,
    discover_title,
    discover_text,
    favorite_title,
    favorite_text,
    order_title,
    order_text,
    footer,
  } = blok
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)

  return (
    <SbEditable content={blok}>
      <Flex
        className={classnames(
          activeMenu === 'guide'
            ? [styles.overlay, styles.active].join(' ')
            : styles.overlay,
          'show-editor'
        )}
      >
        <Flex className={styles.modal} height="100%">
          <Flex
            className={styles.wrapper}
            flexDirection="column"
            alignItems="center"
            paddingTop={[5]}
            paddingBottom={[10]}
            width={1}
          >
            <Flex
              justifyContent="flex-end"
              width={1}
              paddingX={[5]}
              marginBottom={[17]}
            >
              <Box
                as="button"
                onClick={activeMenu ? () => setActiveMenu('') : null}
                className={styles.close}
                aria-label="Close menu"
              >
                <CloseIcon />
              </Box>
            </Flex>
            <Flex
              textAlign="center"
              paddingX={[23]}
              flexDirection="column"
              alignItems="center"
            >
              <Box marginBottom={[8]}>
                {title && <Box className={styles.title}>{title}</Box>}
                {text && (
                  <Box marginTop={[4]}>
                    <Markdown source={text} />
                  </Box>
                )}
              </Box>
              <Flex
                flexDirection="column"
                alignItems="center"
                marginBottom={[8]}
              >
                <Flex
                  className={classnames(styles.badge, styles.rose)}
                  alignItems="center"
                  justifyContent="center"
                  marginBottom={[4]}
                >
                  <MagnifyGlass />
                </Flex>
                {discover_title && (
                  <Box className={styles.subTitle}>{discover_title}</Box>
                )}
                {discover_text && (
                  <Box marginTop={[2]}>
                    <Markdown source={discover_text} />
                  </Box>
                )}
              </Flex>
              <Flex
                flexDirection="column"
                alignItems="center"
                marginBottom={[8]}
              >
                <Flex
                  className={classnames(styles.badge, styles.sand)}
                  alignItems="center"
                  justifyContent="center"
                  marginBottom={[4]}
                >
                  <Heart />
                </Flex>
                {favorite_title && (
                  <Box className={styles.subTitle}>{favorite_title}</Box>
                )}
                {favorite_text && (
                  <Box marginTop={[2]}>
                    <Markdown source={favorite_text} />
                  </Box>
                )}
              </Flex>
              <Flex
                flexDirection="column"
                alignItems="center"
                marginBottom={[8]}
              >
                <Flex
                  className={classnames(styles.badge, styles.lightGreen)}
                  alignItems="center"
                  justifyContent="center"
                  marginBottom={[4]}
                >
                  <BagWithCheckMark width={16} height={16} />
                </Flex>
                {order_title && (
                  <Box className={styles.subTitle}>{order_title}</Box>
                )}
                {order_text && (
                  <Box marginTop={[2]}>
                    <Markdown source={order_text} />
                  </Box>
                )}
              </Flex>
              <Box>
                {footer && (
                  <Box marginTop={[2]}>
                    <Markdown source={footer} />
                  </Box>
                )}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default GuidePopup

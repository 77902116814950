// extracted by mini-css-extract-plugin
export var arrowIcon = "Highlight-module--arrowIcon--w5per";
export var content = "Highlight-module--content--W5FkA";
export var fixedTheme = "Highlight-module--fixedTheme--JQGS9";
export var image = "Highlight-module--image--Zk3FX";
export var label = "Highlight-module--label--HXR4L";
export var largeTheme = "Highlight-module--largeTheme--oJ4u1";
export var lightTheme = "Highlight-module--lightTheme--DIM8X";
export var link = "Highlight-module--link--984in";
export var smallTheme = "Highlight-module--smallTheme--0047R";
export var title = "Highlight-module--title--G6yC3";
export var video = "Highlight-module--video--3ZXR-";
export var wrapper = "Highlight-module--wrapper--Wm+eS";
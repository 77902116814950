import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import Image from 'src/components/UI/Image/Image'

import { Props } from './types'

const ImageWithLink = ({ blok, className }: Props): JSX.Element => {
  const { image, link } = blok
  const { url, title } =
    link && link?.length > 0 ? getLinkAttributes(link[0]) : {}
  return url && title && image?.filename ? (
    <SbEditable content={blok}>
      <LinkComponent className={className} to={url} title={title}>
        <Image
          src={image.filename}
          alt={title}
          sizes={{
            mobile: '50vw',
            tablet: '33vw',
            desktop: '33vw',
          }}
        />
      </LinkComponent>
    </SbEditable>
  ) : (
    <Box />
  )
}

export default ImageWithLink

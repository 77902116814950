// extracted by mini-css-extract-plugin
export var active = "NavigationDesktop-module--active--3OyDV";
export var group = "NavigationDesktop-module--group--278Nu";
export var groupContent = "NavigationDesktop-module--groupContent--I6AjB";
export var groupTitle = "NavigationDesktop-module--groupTitle--iZZ1n";
export var groupTitleBorder = "NavigationDesktop-module--groupTitleBorder--phQ+v";
export var isTopPage = "NavigationDesktop-module--isTopPage--OYLYG";
export var navigationLink = "NavigationDesktop-module--navigationLink--4boLD";
export var navigationLinksTitle = "NavigationDesktop-module--navigationLinksTitle--Z-TWE";
export var overlay = "NavigationDesktop-module--overlay--vHWJ4";
export var underlineLink = "NavigationDesktop-module--underlineLink--qnrfW";
export var wrapper = "NavigationDesktop-module--wrapper--Gf4v6";
import * as React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

import { gtagEvent } from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'

import { Props } from './types'

const LinkComponent = ({
  to,
  children,
  onClick,
  className,
  title,
  onMouseEnter,
  onMouseLeave,
  style,
}: Props): JSX.Element => {
  if (!to) {
    return <React.Fragment>{children}</React.Fragment>
  }

  const isMail = to.includes('mailto:')
  const isTel = to.includes('tel:')
  const isDownload = to.includes('data:text')
  const isExternal = to.includes('http') || isMail || isTel || isDownload

  const locationHref = useLocation ? useLocation().href : ''

  // Check for missing "/" in relative urls
  if (!isExternal && !to.startsWith('/')) {
    to = `/${to}`
  }

  // Remove home from slug
  if (!isExternal && to === '/home') {
    to = `/`
  }

  return isExternal ? (
    <a
      className={className}
      href={to}
      onClick={() => {
        if (onClick) {
          onClick()
        }

        // Track events
        if (isMail) {
          gtagEvent({
            action: 'Mailto Link',
            category: 'Click',
            label: locationHref,
          })
        }
        if (isTel) {
          gtagEvent({
            action: 'Tel Link',
            category: 'Click',
            label: locationHref,
          })
        }
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      style={style}
      download={isDownload}
    >
      {children}
    </a>
  ) : (
    <Link
      style={style}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
      to={to}
    >
      {children}
    </Link>
  )
}

export default LinkComponent

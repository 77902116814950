import { SbLink } from 'src/storyblok/types/storyblokTypes'

export const getLinkAttributes = (link: SbLink) => {
  const url = link?.slug?.cached_url
    ? link.slug.cached_url
    : link?.slug?.email
    ? `mailto:${link.slug.email}`
    : ''
  const title = link?.title ? link.title : ''

  return {
    url,
    title,
  }
}

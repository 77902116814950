import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import { useRecoilState } from 'recoil'
import classnames from 'classnames'

import { activeMenuState } from 'src/recoil/atoms'
import { SbLink } from 'src/storyblok/types/storyblokTypes'
import Highlight from 'src/storyblok/Components/Highlight/Highlight'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { Logotype } from 'src/components/UI/Logotype/Logotype'
import { PlusIcon, CloseIcon } from 'src/components/UI/Icon/Icon'

import * as styles from './NavigationMobile.module.scss'
import { Props } from './types'

const NavigationMobile = ({
  navigationGroups,
  uspBarLinks,
}: Props): JSX.Element => {
  const [, setActiveMenu] = useRecoilState(activeMenuState)
  const [activeGroup, setActiveGroup] = useState(
    navigationGroups?.length > 0 ? navigationGroups[0]._uid : ''
  )

  const closeMenu = () => {
    setActiveMenu('')
    setActiveGroup(navigationGroups?.length > 0 ? navigationGroups[0]._uid : '')
  }
  return (
    <Box className={styles.wrapper}>
      <Flex
        className={styles.header}
        alignItems="center"
        justifyContent="space-between"
        paddingX={5}
      >
        <Box
          as="button"
          aria-label="Close menu"
          className={styles.menuButton}
          onClick={() => closeMenu()}
        >
          <CloseIcon />
        </Box>
        <Flex flex="2" justifyContent="center" width={1}>
          <LinkComponent
            to="/"
            className={styles.logotype}
            onClick={() => closeMenu()}
          >
            <Logotype />
          </LinkComponent>
        </Flex>
        <Box width="20px" />
      </Flex>
      <Box paddingY={4}>
        {navigationGroups?.length > 0 &&
          navigationGroups.map((group, index) => {
            const { highlights } = group

            return highlights?.length > 0 ? (
              <Box
                key={group._uid}
                className={classnames(
                  styles.highlights,
                  activeGroup === group._uid || (!activeGroup && index == 0)
                    ? styles.active
                    : ''
                )}
              >
                <Flex width="130%">
                  {highlights.map((blok, index) => {
                    return (
                      <Box
                        key={`mobile-nav-${blok._uid}`}
                        width={1}
                        marginLeft={index === 0 ? 5 : 2}
                        marginRight={index === highlights.length - 1 ? 5 : 0}
                      >
                        <Highlight
                          key={blok._uid}
                          blok={blok}
                          type="fixed"
                          size="small"
                          onClick={() => closeMenu()}
                        />
                      </Box>
                    )
                  })}
                </Flex>
              </Box>
            ) : null
          })}
      </Box>
      <Box className={styles.navigation}>
        {navigationGroups?.length > 0 &&
          navigationGroups.map((group) => {
            const { link, columns } = group
            const { title, url } =
              link && link?.length > 0 ? getLinkAttributes(link[0]) : {}
            return (
              <Box
                key={group._uid}
                className={classnames(
                  styles.group,
                  activeGroup === group._uid ? styles.active : ''
                )}
                paddingY={3}
              >
                {columns?.length > 0 ? (
                  <Flex
                    paddingX={5}
                    as="button"
                    alignItems="center"
                    justifyContent="space-between"
                    className={styles.groupTitle}
                    width={1}
                    onClick={() =>
                      setActiveGroup(
                        activeGroup === group._uid ? '' : group._uid
                      )
                    }
                  >
                    <Box as="span" className={styles.text}>
                      {title}
                    </Box>
                    <Flex
                      as="span"
                      alignItems="center"
                      justifyContent="center"
                      className={styles.plusIcon}
                    >
                      <PlusIcon />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    paddingX={5}
                    as="button"
                    alignItems="center"
                    justifyContent="space-between"
                    className={styles.groupTitle}
                    width={1}
                  >
                    <LinkComponent to={url} onClick={() => closeMenu()}>
                      <Box as="span" className={styles.text}>
                        {title}
                      </Box>
                      <Flex
                        as="span"
                        alignItems="center"
                        justifyContent="center"
                        className={styles.plusIcon}
                      ></Flex>
                    </LinkComponent>
                  </Flex>
                )}
                <Box className={styles.groupContent}>
                  {columns?.length > 0 &&
                    columns.map((column) => {
                      const { items } = column
                      return (
                        <React.Fragment key={column._uid}>
                          {items?.length > 0 &&
                            items.map((item: any | SbLink) => {
                              const link =
                                item.component === 'link'
                                  ? getLinkAttributes(item)
                                  : null
                              const links =
                                item.component === 'link_list' &&
                                item?.links.length > 0
                                  ? item.links
                                  : null
                              const [toggleLinks, setToggleLinks] = useState(
                                false
                              )
                              const mark_last_link =
                                item &&
                                item.component === 'link_list' &&
                                item.mark_last_link
                                  ? item.mark_last_link
                                  : false

                              return link ? (
                                <Flex
                                  key={item._uid}
                                  className={styles.navigationLink}
                                  paddingX={5}
                                  alignItems="center"
                                >
                                  <LinkComponent
                                    to={link.url}
                                    onClick={() => closeMenu()}
                                  >
                                    {link.title}
                                  </LinkComponent>
                                </Flex>
                              ) : links?.length > 0 ? (
                                <Flex
                                  key={item._uid}
                                  flexDirection="column"
                                  className={classnames(
                                    styles.navigationLinks,
                                    toggleLinks ? styles.active : ''
                                  )}
                                >
                                  {item?.title && (
                                    <Flex
                                      as="button"
                                      className={styles.navigationLinksTitle}
                                      paddingX={5}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      width={1}
                                      onClick={() =>
                                        setToggleLinks(!toggleLinks)
                                      }
                                    >
                                      <Box as="span" className={styles.text}>
                                        {item.title}
                                      </Box>
                                      <Flex
                                        as="span"
                                        alignItems="center"
                                        justifyContent="center"
                                        className={styles.plusIcon}
                                      >
                                        <PlusIcon />
                                      </Flex>
                                    </Flex>
                                  )}
                                  <Box className={styles.linkList}>
                                    {links.map(
                                      (link: SbLink, index: number) => {
                                        const { url, title } = link
                                          ? getLinkAttributes(link)
                                          : {}
                                        return (
                                          <Flex
                                            key={link._uid}
                                            className={classnames(
                                              styles.navigationLink,
                                              mark_last_link &&
                                                index === links.length - 1
                                                ? styles.underlineLink
                                                : ''
                                            )}
                                            paddingX={5}
                                            alignItems="center"
                                            width={1}
                                          >
                                            <LinkComponent
                                              to={url}
                                              onClick={() => closeMenu()}
                                            >
                                              {title}
                                            </LinkComponent>
                                          </Flex>
                                        )
                                      }
                                    )}
                                  </Box>
                                </Flex>
                              ) : null
                            })}
                        </React.Fragment>
                      )
                    })}
                </Box>
              </Box>
            )
          })}
        {uspBarLinks?.length > 0 &&
          uspBarLinks.map((link) => {
            const { url, title } = link ? getLinkAttributes(link) : {}

            return (
              <Box key={url} className={styles.group} paddingY={3}>
                <Flex
                  paddingX={5}
                  alignItems="center"
                  justifyContent="space-between"
                  className={styles.groupTitle}
                  width={1}
                >
                  <LinkComponent to={url} onClick={() => closeMenu()}>
                    <Box as="span" className={styles.text}>
                      {title}
                    </Box>
                    <Flex
                      as="span"
                      alignItems="center"
                      justifyContent="center"
                      className={styles.plusIcon}
                    ></Flex>
                  </LinkComponent>
                </Flex>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}

export default NavigationMobile
